import React, { memo, Fragment, useEffect, useState, useRef } from 'react';
import update from 'immutability-helper';
import styled from 'styled-components';
import classNames from 'classnames'
import { DragDropContext } from 'react-beautiful-dnd';
import { Wrapper, SaveButton, TableHeader, EditorFooter, CustomButton, CancelButton, EditorWrapper } from '../../../reactcomponents/Admin';
import DropDown from '../../../components/DropDown';
import KnowledgeBaseTree from './knowledgeBaseTree';
import CategoryEdit from './edit/categories';
import QuestionEdit from './edit/questions';
import KnowledgeBaseEdit from './edit/knowledgebase';
import RatingEdit from './edit/ratings';
import KnowledgeBaseList from './knowledgeBaseList';
import {
	KBT_CATEGORY,
	KBT_LIBRARY,
	KBT_QUESTION,
	KBT_SUGGESTION,
	KBT_RATING,
	KB_TYPE,
	START_TEMPLATE_DATA,
	END_TEMPLATE_DATA,
	USER_VOTE_UP,
	USER_VOTE_DOWN,
} from '../../../common/v5/constants';
import Helper from '../../../reactcomponents/Helper';
import { stripHTML, getChevronIcon, moveObjectInArray } from '../../../common/v5/helpers';
import PopupPage from '../../../reactcomponents/PopupPage';
import { TableIconicButton, TextInputRow, FormInputWithLabelRow, SelectInputRow } from '../../../reactcomponents/Form';
import SwitchCheckbox from '../../../reactcomponents/SwitchCheckbox';
import Ckeditor from '../../../components/v5/Ckeditor';
import ToolbarButtonIcon from '../../../reactcomponents/ToolbarButtonIcon';
import ToggleSwitch from '../../../components/v5/ToggleSwitch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OneButtonPad, ButtonsPad } from '../../../reactcomponents/DialPad';
import { TemplateAssist } from '../../../reactcomponents/Assist';
import { LibraryQuestionAttachmentList } from './edit/questions';
import { ActionDropdown2, SingleSelectDD } from '../../../reactcomponents/Dropdown';
import { Spinner } from '../../../reactcomponents/Spinner';
import { AddTextItemInputContainer } from '../../../reactcomponents/AddTextItemInput';
import { DeletePopup } from "../../../reactcomponents/Dialog";
import { AIAnswerRightSidepanel } from '../../../reactcomponents/Sidepanel';
import KnowledgeBaseCtnr, { RewriteAnswerCntr } from '../knowledgebase/knowledgeBaseCtnr';
import { withUnmountWhenHidden } from '../../../reactcomponents/hocs';
import { INPUT_EMPTY_WARNING } from '../../../common/constants';
import { ChatbotsTrainPhraseSelection } from '../../../components/v5/KnowledgeBasePopup';
import { AlphabetButton } from '../../../reactcomponents/Button';

const StyledWrapper = styled(Wrapper)`
	&.adminWrapper {
		#knowledge-base-wrapper {
			height: 100%;
			width: 100%;
			.kb-inner-container {
				height: 100%;
				.kb-tree {
					&.left{
						padding: 20px;
					}
					height: 100%;
					.kb-tree-dropdown {
						height: 30px;
					}
					.knowledgebase-tree-container {
						height: calc(100% - 30px);
						overflow: auto;
						.list-container{
							// background: #F0F0F0;
							padding: 8px;
							.icon-chevron-down,.icon-chevron-up{
								color: #0c87f7;
							}
							.inner-container{
								padding-top: 2px;
							}
							.sub-tree-list{
								.list-container{
									padding: 0;
									.main-tree{
										padding-top: 0 !important;
										padding-bottom: 0 !important;
										.outer-container{
											margin-left: 0 !important;
											.inner-container{
												padding-left: 8px;
												padding-top: 4px;
											}
											.sub-tree-list{
												.list-container{
													li{
														padding-left: 8px
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.kb-table {
					height: 100%;
					padding: 20px;
					.kb-header{
						height: 40px;
						width: 100%;
						padding: 0;
					}
					.nav-tabs {
						height: 35px;
						border-bottom: none;
					}
					.kb-table-container {
						height: calc(100% - 75px);
						padding: 15px;
						.admin-profile {
							height: 100%;
							padding: 0;
							.col-md-11 {
								padding: 0;
								width: 100%;
								max-width: unset;
							}
							.col-md-12, .col-md-11{
								text-align: center;
								.currentPage{
									margin-right: 10px;
								}
								.currentPage , .display-paging{
									display: inline-flex;
									// padding-bottom: 15px;
								}
								.display-paging span{
									padding-top: 0px;
								}
								.display-paging .page-arrow{
									line-height: 1.3;
								}
							}
							.loading-spinner {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 100%;
								height: 40px;
								.label {
									margin-right: 4px;
								}
							}
							.buttonShowMoreHistory {
								font-size: 12px;
								text-align: center;
								float: right;
								transition: .5s;
								padding: 0px;
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								height: 40px;
								&:hover {
									// background: #00000029;
									cursor: pointer;
									color: #0c87f7;
									.btn {
										background-color: #7ac0ff4f;
									}
								}
								.btn {
									font-weight: unset;

								}
								.label {
									margin-right: 4px
								}
							}
							#page-wrapper {
								height: 100%;
								.searchBoxOptions {
									height: 40px;
									padding-top 8px;
									display: flex;
									align-items: baseline;
									.bg-transparent:focus{
										box-shadow: none;
									}
									.bg-transparent .icon-add:before{
										background-color: #00000029;
										height: 20px;
										min-width: 20px;
										text-align: center;
										border-radius: 100px;
										font-size: 12px;
										font-weight: 800;
										line-height: 20px;
										padding: 5px;
									}
									.active .icon-add:before{
										background-color: #7ac0ff4f;
									}
								}
								.kb-list-section {
									height: calc(100% - 40px);
									.list-table {
										height: 100%;
										.table-bordered, .table-bordered th, .table-bordered td{
											border: none;
										}
										.table-bordered thead th, .table-bordered tbody tr{
											border: none;
											border-bottom: 1px solid #00000033;
											background-color: transparent;
										}
										.table-bordered thead th {
											&.Action{
												text-align: center !important;
											}
										}

										.table-bordered tbody tr td {
											vertical-align: middle;
											.table-btns button{
												padding: 1px 6px;
												font-size: 16px;
											}
										}
										// &.x-small table{
										// 	min-height: 65vh;
										// }
										// &.small table{
										// 	min-height: 68vh;
										// }
										// &.large table{
										// 	min-height: 72vh;
										// }
										// &.x-large table{
										// 	min-height: 75vh;
										// }
									}
									.list-form {
										overflow: auto;
										top: -15%;
										.col-lg-7{
											flex: 0 0 52%;
											.btn-sm{
												right: 30%;
												position: relative;
												padding: 2px 10px;
												transition: all .35s;
												overflow: hidden;
												font-size: 10px;
												background: white;
												color: #6d6d6d;
												font-weight: 400;
												cursor: pointer;
												top: 2px;
												border: 1px solid #eaeaea;
												input[type=file]{
													font-size: 100px;
													position: absolute;
													top: 0;
													right: 0;
													min-width: 100%;
													min-height: 100%;
													opacity: 0;
													outline: none;
													filter: alpha(opacity=0);
													background: #fff;
													cursor: pointer;
													display: block;
												}
											}
										}

										.archivefile{
											top: 5px;
											position: relative;
											border: 1px solid #eaeaea;
											border-radius: 5px;
											font-size: 10px;
											padding: 5px;
										}
									}
								}
							}
						}
					}
				}
				.list-form {
					overflow: auto;
					.knowledgebase-heading {
						height: 40px;
						display: inline-flex;
						align-items: center;
						font-size: 16px;
						width: 100%;
						col-split-left{
							width: 100%;
							.admin-icons{
								float: left;
							}
						}
					}
					.col-lg-8{
						flex: 0 0 64%;
						.btn-sm{
							right: 25%;
							position: relative;
							padding: .25rem 16px;
							transition: all .35s;
							overflow:hidden;
							font-size: 10px;
							background: white;
							color: #6d6d6d;
							font-weight: 400;
							cursor: pointer;
							border-radius: 5px;
							border: 1px solid #eaeaea;
							input[type=file]{
								font-size: 100px;
								position: absolute;
								top: 0;
								right: 0;
								min-width: 100%;
								min-height: 100%;
								opacity: 0;
								outline: none;
								filter: alpha(opacity=0);
								background: #fff;
								cursor: pointer;
								display: block;
							}
						}
					}
					.col-lg-8{
						input[type=text]{
							width: 100%;
						}
					}
					.archivefile{
						position: relative;
						border: 1px solid #eaeaea;
						border-radius: 5px;
						font-size: 10px;
						padding: .25rem 5px;
						display: inline-flex;
						line-height: 1.5;
					}
				}
			}
		}
		overflow-x: hidden;
		.left {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.MultiPurposeDropdown {
			:focus {
				outline-color: transparent;
				outline-style: none;
			}
			button {
				background: transparent;
				border-radius: 3px;
				border: 1px solid #00000033;
				&::after {
					display: none;
				}
			}
			button:not(:first-child) {
				border-radius: 0px 3px 3px 0px;
				padding-left: 9px;
				padding-right: 10px;
				outline: none;
			}
			.forwardToExternalDropdown {
				background:  #f0ad4e;
				color: #fff;
			}
			outline-color: transparent;
			outline-style: none;
		}
		.far {
			color: black;
		}
		.fas {
		}
		.open>.dropdown-menu {
			display: block;
			width: max-content;
		}
		.nav-link {
			font-size: 12px;
			padding: .5rem .5rem;
			color: inherit;
			&.active{
				border-bottom-color: #0c87f7;
				border-width: 0 0 thick 0;
				color: #0c87f7;
			}
		}
		.previewer {
			position: absolute;
			background: #fff;
			overflow: hidden;
			z-index: 1002;
			width: 250px;
			left: 35px;
			.arrow-up {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-bottom: 5px solid #0779bf;
				font-size: 0;
				line-height: 0;
				margin-left: 10px;
			}
			.pre_header {
				color: #fff;
				background: #0779bf;
				line-height: 20px;
				padding-left: 10px;
				padding: 4px 8px;
				border-top: 1px solid #2e6da4;
				border-left: 1px solid #2e6da4;
				border-right: 1px solid #2e6da4;
			}
			.pre_title {
				padding: 4px 8px;
				background: #f8f8f8;
				border-left: 1px solid #efefef;
				border-right: 1px solid #efefef;
				span {
					font-weight: 700;
				}
			}
			.pre_body {
				padding: 4px 8px;
				max-height: 200px;
				overflow-y: scroll;
				color: #333;
				border-left: 1px solid #efefef;
				border-right: 1px solid #efefef;
				border-bottom: 1px solid #efefef;
				ul {
					list-style-type: square;
				}
			}
		}
		.admin-profile {
			// border-left: 1px solid #e7e7e7;
			// border-left-width: 1px;
			// border-left-style: solid;
			// border-left-color: rgb(231, 231, 231);
			// border-left-width: 1px;
			padding-left: 20px;
		}
		.external {
			padding-bottom: 12px;
		}
		.editPageActionButton {
			margin-bottom: 28px;
			padding-bottom: 2px;
		}
		.btn-xs {
			padding: 1px 5px;
			font-size: 12px;
			line-height: 1.5;
			border-radius: 3px;
			&::after {
				display: none;
			}
		}
		.btn-sm {
			border-radius: 3px;
			font-size: 12px;
			line-height: 1.5;
			padding: 3px 10px;
		}
		.table {
			font-family: Nunito Sans;
			td {
				padding: 0.55rem;
			}
		}
		.multiSelect {
			background: #868686;
			color: #fff;
			padding-bottom: 1px;
			padding-top: 1px;
			a {
				font-size: 14px;
				font-weight: 400;
				line-height: 1.5;
				padding-left: 20px;
				padding-right: 20px;
				white-space: nowrap;
			}
		}
		.normalSelect {
			padding-bottom: 1px;
			padding-top: 1px;
			a {
				cursor: pointer;
				font-size: 13px;
				font-weight: 400;
				padding: 3px 20px;
			}
		}
		.admin-profile {
			font-size: 12px;
		}
		.card {
			margin-bottom: 20px;
			border: none;
			box-shadow: 0 3px 8px 1.5px #7ac0ff82;
			.card-body{
				.form-group{
					font-size: 12px;
					color: #6d6d6d;
					margin-top: 20px;
					.c3-ckeditor{
						border: 1px solid #ced4da;
						border-radius: .2rem;
						.cke_top{
							border-top: none;
						}
					}

					label{
						margin-bottom: 0px;
					}

					.kb-label{
						padding: .375rem .75rem;
					}

					.control-label{
						padding: 0px .75rem;
					}

					.btn-xs{
						line-height: 1;
						padding: .5rem .5rem;
					}

					.attachFileArchive{
						padding-left: -2px;
					}

					.question-button{
						.btn-blue, .btn-white{
							padding: 5px 30px;
							line-height: 1.2rem;
						}
						.btn-sm {
							line-height: 1.2rem;
							padding: 5px 24px;
							border-radius: 0px;
						}
						span{
							display: inline-flex
						}
						button{
							margin: 0px 1.5px;
						}
					}

					.rating-button{
						.btn-warning{
							padding: 5px 28px;
						}
						.btn-primary{
							padding: 5px 10px;
						}
						.btn-sm{
							border-radius: 0px;
						}
					}

					.keyboardContainer{
						.col-lg-2,.col-lg-3{
							padding: 0 5px;
							input[type=text]{
								margin-top: 0px;
							}
						}
						label{
							padding: 2px;
							margin: 0 0 10px;
							display: block;
							border-radius: 5px;
							text-align: center;
							border: 1px solid #ced4da;
							input{
								display: none;
							}

						}
						label:hover{
							cursor: pointer;
							border-color: #0C87F7;
							background: #E0F3FF;
							color: #0C87F7;
						}
						label.active{
							border-color: #0C87F7;
							background: #E0F3FF;
							color: #0C87F7;
						}
						input.containKey{
							border-color: #0C87F7;
							background: #E0F3FF;
							color: #0C87F7;
						}
					}
					input[type=text]{
						border-radius: 5px;
						&.form-control-sm{
							border-radius: 7px;
							padding: .375rem .75rem;
						}
					}
				}
				.questionTabEdit{
					min-height: 300px;
					.row{
						display: initial;
					}
				}
			}
		}
		.col-form-label {
			input {
				margin-top: 9px;
			}
		}
		input[type=checkbox] {
			margin-top: 9px;
		}
		.form-group {
			margin-bottom: 7px;
			.label-row{
				display: flex;
				.icon-search{
					padding: .8rem .0em;
				}
				.label-block{
					display: inline-flex;
					width: 100%;
					.agentSearchBox{
						border: 1px solid transparent;
						.form-control{
							border-bottom: 2px solid #6d6d6d;
						}
					}
				}
			}
		}
		.input-group-addon {
			height: 27px;
		}
		.editPageActionButton {
			.textAlignRight {
				text-align: right;
				margin-top: 10px;
				margin-bottom: 10px;
				.btn {
					margin: 0px 1.5px;
					color: white;
				}
			}
		}
		.upload-file {
			width: 87px;
		}
		@media only screen and (max-width: 1000px)  {
			.row {
				.col-md-3 .left {
					padding-bottom: 10px;
				}
				.col-md-9 {
					flex: 0 0 97%;
					max-width: 100%;
				}
			}
			.form-group {
				display: flex !important;
			}
			.left {
				flex: 0 0 100%;
				width: 100%;
				max-width: 100%;
				padding-bottom: 10px;
			}
			.card-body {
				.col-lg-2 {
					width: 15%;
				}
				.col-lg-9 {
					width: 75%;
				}
				.col-lg-1 {
					width: 10%;
				}
				.col-lg-3 {
					width:25%;
				}
				.col-lg-8 {
					width: 65%;
				}
				.col-lg-1 {
					width: 10%;
				}
			}
		}
	}
`

function findAllQuestions(node) {
	let questions = [];
	function traverse(node) {
		if (node.type === "question") {
			questions.push(node);
		}
		if (node.list && Array.isArray(node.list)) {
			for (let child of node.list) {
				traverse(child);
			}
		}
	}
	traverse(node);
	return questions;
}

const KB_TAB_LIBRARY = 0;
const KB_TAB_CATEGORY = 1;
const KB_TAB_QUESTION = 2;
const KB_TAB_SUGGESTION = 3;
const KB_TAB_RATING = 4;
class KnowledgeBase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			copyNode: {},
			copyRow: {},
			copyState: false,
			edit: {
				show: false,
				title: '',
				data: {}
			}
		}
	};
	componentDidMount() {
		if(!this.props.forErrand) {
			if(!cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
				this.props.onLoadAgentTemplates();
				this.props.onLoadTreeRoots();
				const { id, subPath } = this.props;
				if(id) {
					if(subPath == "category") {
						this.props.onChangeActiveTab(KB_TAB_CATEGORY);
						this.props.onHandleEdit(KBT_CATEGORY, id);
					} else if(subPath == "question") {
						this.props.onChangeActiveTab(KB_TAB_QUESTION);
						this.props.onHandleEdit(KBT_QUESTION, id);
					} else if(subPath == "suggestion") {
						this.props.onChangeActiveTab(KB_TAB_SUGGESTION);
						this.props.onHandleEdit(KBT_SUGGESTION, id);
					} else {
						this.props.onChangeActiveTab(KB_TAB_LIBRARY);
						this.props.onHandleEdit(KBT_LIBRARY, id);
					}
				}
			} else {
				this.props.onLoadAgentTemplates();
			}
		} else {
			this.props.onLoadList(KBT_CATEGORY, {}, false);
		}
	};
	componentDidUpdate(prevProps) {
		if(!this.props.forErrand) {
			if(!cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
				if (this.props.deletedId && this.props.deletedId != prevProps.deletedId) {
					this.resetTabAndReloadTree();
				}
				if (this.props.edit != prevProps.edit) {
					this.handleEdit(this.props.edit)
				};
			}
		}
	};

	handleEdit = (id) => {
		if (id > 0) {
			var type = KB_TYPE[this.props.activeTab].toString();
			this.props.onHandleEdit(type, id)
		}
	}

	handleTabClick = (event) => {
		var tab = parseInt(event.target.parentNode.id.substring(11));
		this.props.onChangeActiveTab(tab);
	};
	handleNodeRemove = (target, container) => {
		var parameters = {
			containerType: container.type,
			containerId: container.id,
			nodeId: target.nodeId
		};
		let confirmDelMsg = I('Are you sure you want to delete this instance?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			this.props.onRemoveNode(parameters);
		}
	};
	resetTabAndReloadTree = () => {
		this.props.onResetEdit();
		this.props.onLoadTreeRoots();
	};
	handleDrop = (event, target, container) => {
		var json = event.dataTransfer.getData('Text');
		var item = null;
		if (json) {
			try {
				item = JSON.parse(json);
			} catch (e) {
				item = null;
			}
		}
		if (item) {
			var parameters = {
				afterNodeId: (target ? target.nodeId : 0)
				, containerId: container.id
				, containerType: container.type
				, currentContainerId: item.currentContainerId
				, currentContainerType: item.currentContainerType
				, currentNodeId: item.currentNodeId
				, itemId: item.id
				, itemType: item.type
			};
			this.props.onInsertKnowledgeBaseNode(parameters);
		}
	};
	handleDropNewKB = (nodeInfo) => {
		this.props.onInsertKnowledgeBaseNode(nodeInfo);
	}
	handleCopyRow = (event, type) => {
		var id = event.target.parentNode.parentNode.parentNode.id.substring('itemRow_'.length);
		this.setState({
			copyRow: {
				type: type
				, id: id
			}
		});
		if (this.state.copyState) {
			this.setState({ copyState: false })
		} else {
			this.setState({ copyState: true })
		}
	};
	handleCopyNode = (event, item, container) => {
		this.setState({
			copyNode: {
				currentContainerId: container.id
				, currentContainerType: container.type
				, currentNodeId: item.nodeId
				, id: item.id
				, type: item.type
			}
		});
		if (this.state.copyState) {
			this.setState({ copyState: false })
		} else {
			this.setState({ copyState: true })
		};
	};
	handlePaste = (target, container) => {
		let item = '';
		if (this.state.copyRow != {}) {
			item = this.state.copyRow
			let parameters = {
				afterNodeId: (target ? target.nodeId : 0)
				, containerId: container.id
				, containerType: container.type
				, currentContainerId: item.currentContainerId
				, currentContainerType: item.currentContainerType
				, currentNodeId: item.currentNodeId
				, itemId: item.id
				, itemType: item.type
			}
			this.props.onInsertKnowledgeBaseNode(parameters);
			this.setState({
				copyState: false
				, copyRow: {}
			})
		}
		if (this.state.copyNode) {
			item = this.state.copyNode;
			let parameters = {
				afterNodeId: (target ? target.nodeId : 0)
				, containerId: container.id
				, containerType: container.type
				, currentContainerId: item.currentContainerId
				, currentContainerType: item.currentContainerType
				, currentNodeId: item.currentNodeId
				, itemId: item.id
				, itemType: item.type
			}
			this.props.onInsertKnowledgeBaseNode(parameters);
			this.setState({
				copyState: false
				, copyNode: {}
			})
		}
	};
	handlePasteInto = (data) => {
		let item = {};
		if (this.state.copyRow) {
			item = this.state.copyRow;
			let parameters = {
				afterNodeId: 0
				, containerId: data.id
				, containerType: data.type
				, currentContainerId: item.currentContainerId
				, currentContainerType: item.currentContainerType
				, currentNodeId: item.currentNodeId
				, itemId: item.id
				, itemType: item.type
			}
			this.props.onInsertKnowledgeBaseNode(parameters);
			this.setState({
				copyState: false
				, copyRow: {}
			})
		}
		if (this.state.copyNode) {
			item = this.state.copyNode;
			let parameters = {
				afterNodeId: 0
				, containerId: data.id
				, containerType: data.type
				, currentContainerId: item.currentContainerId
				, currentContainerType: item.currentContainerType
				, currentNodeId: item.currentNodeId
				, itemId: item.id
				, itemType: item.type
			}
			this.props.onInsertKnowledgeBaseNode(parameters);
			this.setState({
				copyState: false
				, copyNode: {}
			})
		}
	};
	handleRemove = (itemId) => {
		var treeRootId = '';
		if (this.state.activeTab == 0 /* library */) {
			treeRootId = 'l' + itemId;
		} else if (this.state.activeTab == 1 /* category */) {
			treeRootId = 'c' + itemId;
		}
		if (treeRootId == this.state.selectedTreeRoot) {
			var state = update(this.state, { selectedTreeRoot: { $set: '' } });
			this.setState(state);
		}
		this.resetTabAndReloadTree();
	};
	handleCancel = () => {
		this.props.onResetEdit();
	}
	handleSave = (data) => {
		if (data.name) {
			data.name = data.name.trim();
		}
		if (data.subject) {
			data.subject = data.subject.trim();
		}
		var type = KB_TYPE[this.props.activeTab].toString();
		this.props.onHandleSave(type, data);
	}
	handleRemoveEdit = (id) => {
		var type = KB_TYPE[this.props.activeTab].toString();
		this.props.onHandleRemove(type, id);
	}
	onLibraryDragStart = (event) => {
		var id = event.target.parentNode.id.substring('itemRow_'.length);
		event.dataTransfer.setData('Text', JSON.stringify({
			type: KBT_LIBRARY,
			id: id
		}));
		event.effectAllowed = 'link';
	};
	onCategoryDragStart = (event) => {
		var id = event.target.parentNode.id.substring('itemRow_'.length);
		event.dataTransfer.setData('Text', JSON.stringify({
			type: KBT_CATEGORY,
			id: id
		}));
		event.effectAllowed = 'link';
	};
	onQuestionDragStart = (event) => {
		var id = event.target.parentNode.id.substring('itemRow_'.length);
		event.dataTransfer.setData('Text', JSON.stringify({
			type: KBT_QUESTION,
			id: id
		}));
		event.effectAllowed = 'link';
	};
	handleNodeDragStart = (event, item, container) => {
		if(event) {
			event.dataTransfer.setData('Text', JSON.stringify({
				type: item.type,
				id: item.id,
				currentContainerType: container.type,
				currentContainerId: container.id,
				currentNodeId: item.nodeId
			}));
			event.effectAllowed = 'link';
		}
	};
	render() {
		const {
			activeTab
			, chatFeature
			, deletedId
			, edit
			, noForBotQuestion
			, onLoadList
			, onChangeActiveTab
			, onChangeTreeRoot
			, onEditQuestionRating
			, onCreateNew
			, onFetchKnowledeBaseCategory
			, onFetchKnowledgeBase
			, onHandleEdit
			, onHandleRemove
			, onHandleSave
			, onResetEdit
			, onAppendTemplate
			, savedId
			, selectedTreeRoot
			, selectedTreeRootCategory
			, selectedTreeRootLibrary
			, showMore
			, tableList
			, treeList
			, treeRoots
			, insertedNode
			, removedNode
			, templates
			, insertionText
			, onResetInsertionText
			, fetchingLibrary
		} = this.props;
		const { tab } = this.state;
		var tabs = [], numTabs = 5, editStyle = {}, dropDownStyle = {};
		var iconTimeStyle = {
			cursor: 'pointer'
		};
		if (!this.props.edit.show) editStyle.display = 'none';
		if (this.props.edit.show) dropDownStyle.display = 'none';
		for (var i = 0; i < numTabs; i++) {
			var tabClass = '';
			var contentStyle = { display: 'none' };
			if (i == activeTab) {
				tabClass = 'active';
				contentStyle = {};
			}
			tabs.push({ tabClass: tabClass, contentStyle: contentStyle });
		};
		const NEW_KB_GUI = cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui");
		return (
			<StyledWrapper id="wrapper">
				<div id="knowledge-base-wrapper">
					{
						!NEW_KB_GUI ?
						<div className="row kb-inner-container m-0">
						<div className="kb-table col-md-6">
							<div className="kb-header col-md-12">
								<h2>{I('Knowledge bases')}</h2>
							</div>
							<ul className="nav nav-tabs col-md-12">
								<li role="presentation" id={"libraryTab_"+KB_TAB_LIBRARY} className="nav-item" data-qa-id="QA_tab_library">
									<a className={`nav-link ${tabs[0].tabClass}`} href="#library"
										onClick={this.handleTabClick}>{I('Knowledge bases')}
									</a>
								</li>
								<li role="presentation" id={"libraryTab_"+KB_TAB_CATEGORY} className="nav-item" data-qa-id="QA_tab_category">
									<a className={`nav-link ${tabs[1].tabClass}`} href="#library"
										onClick={this.handleTabClick}>{I('Categories')}
									</a>
								</li>
								<li role="presentation" id={"libraryTab_"+KB_TAB_QUESTION} className="nav-item" data-qa-id="QA_tab_questions">
									<a className={`nav-link ${tabs[2].tabClass}`} href="#library"
										onClick={this.handleTabClick}>{I('Questions')}
									</a>
								</li>
								<li role="presentation" id={"libraryTab_"+KB_TAB_SUGGESTION} className="nav-item" data-qa-id="QA_tab_suggestions">
									<a className={`nav-link ${tabs[3].tabClass}`} href="#library"
										onClick={this.handleTabClick}>{I('Suggestions')}
									</a>
								</li>
								<li role="presentation" id={"libraryTab_"+KB_TAB_RATING} className="nav-item" data-qa-id="QA_tab_ratings">
									<a className={`nav-link ${tabs[4].tabClass}`} href="#library"
										onClick={this.handleTabClick}>{I('Ratings')}
									</a>
								</li>
							</ul>

							<div className="kb-table-container col-md-12" style={tabs[0].contentStyle}>
								<KnowledgeBaseList
									createTitle={I('Add new')}
									deletedId={deletedId}
									draggable={true}
									edit={edit}
									editTitle={I('Edit')}
									list={tableList.libraryList}
									onCopyRow={this.handleCopyRow}
									onCreateNew={onCreateNew}
									onDragStart={this.onLibraryDragStart}
									onHandleEdit={onHandleEdit}
									onHandleRemove={onHandleRemove}
									onHandleSave={onHandleSave}
									onRemove={this.handleRemove}
									onResetEdit={onResetEdit}
									onLoadList={onLoadList}
									resetTabAndReloadTree={this.resetTabAndReloadTree}
									// renderEdit={this.renderLibraryEdit}
									savedId={savedId}
									showPreview={true}
									showColumnCopy={true}
									showColumnRemove={true}
									showColumnVote={false}
									showMore={NEW_KB_GUI ? true : showMore.library}
									tableColumnHeader={I('Name')}
									tableId="KnowledgeBaseLibrariesTable"
									type={KBT_LIBRARY}
									knowledgeBase={true}
									forErrand={this.props.forErrand}
								/>
							</div>
							<div className="kb-table-container col-md-12" style={tabs[1].contentStyle}>
								<KnowledgeBaseList
									createTitle={I('Create Category')}
									onLoadList={onLoadList}
									deletedId={deletedId}
									draggable={true}
									edit={edit}
									editTitle={I('Edit Category')}
									list={tableList.categoryList}
									onCopyRow={this.handleCopyRow}
									onCreateNew={onCreateNew}
									onDragStart={this.onCategoryDragStart}
									onHandleEdit={onHandleEdit}
									onHandleRemove={onHandleRemove}
									onHandleSave={onHandleSave}
									onRemove={this.handleRemove}
									onResetEdit={onResetEdit}
									resetTabAndReloadTree={this.resetTabAndReloadTree}
									// renderEdit={this.renderCategoryEdit}
									savedId={savedId}
									showPreview={true}
									showColumnCopy={true}
									showColumnRemove={true}
									showColumnVote={false}
									showMore={NEW_KB_GUI ? true : showMore.category}
									tableColumnHeader={I('Name')}
									tableId="KnowledgeBaseCategoriesTable"
									type={KBT_CATEGORY}
									forErrand={this.props.forErrand}
								/>
							</div>
							<div className="kb-table-container col-md-12" style={tabs[2].contentStyle}>
								<KnowledgeBaseList
									createTitle={I('Create Question')}
									onLoadList={onLoadList}
									deletedId={deletedId}
									draggable={true}
									edit={edit}
									editTitle={I('Edit Question')}
									list={tableList.questionList}
									incompletePagination={NEW_KB_GUI ? true : showMore.question}
									noForBotQuestion={noForBotQuestion}
									onCopyRow={this.handleCopyRow}
									onCreateNew={onCreateNew}
									onDragStart={this.onQuestionDragStart}
									onHandleEdit={onHandleEdit}
									onHandleRemove={onHandleRemove}
									onHandleSave={onHandleSave}
									onRemove={this.handleRemove}
									onResetEdit={onResetEdit}
									onAppendTemplate={onAppendTemplate}
									templates={templates}
									insertionText={insertionText}
									onResetInsertionText={onResetInsertionText}
									resetTabAndReloadTree={this.resetTabAndReloadTree}
									// renderEdit={this.renderQuestionEdit}
									savedId={savedId}
									showExtraColumn={true}
									showKeyboardShortcuts={chatFeature}
									showPreview={true}
									showColumnCopy={true}
									showColumnRemove={true}
									showColumnVote={false}
									showMore={NEW_KB_GUI ? true : showMore.question}
									showSearchOptions={true}
									tableColumnHeader={I('Name')}
									tableId="KnowledgeBaseQuestionsTable"
									type={KBT_QUESTION}
									onDragnDropFile={this.props.onDragnDropFile}
									fileArchiveImages={this.props.archiveImgs}
									fetchingQuestion={this.props.fetchingQuestion}
									forErrand={this.props.forErrand}
								/>
							</div>
							<div className="kb-table-container col-md-12" style={tabs[3].contentStyle}>
								<KnowledgeBaseList
									allowCreateNew={false}
									deletedId={deletedId}
									onLoadList={onLoadList}
									edit={edit}
									editTitle={I('Approve Suggestion')}
									list={tableList.suggestionList}
									noForBotQuestion={noForBotQuestion}
									onCopyRow={this.handleCopyRow}
									onCreateNew={onCreateNew}
									onHandleEdit={onHandleEdit}
									onHandleRemove={onHandleRemove}
									onHandleSave={onHandleSave}
									onResetEdit={onResetEdit}
									resetTabAndReloadTree={this.resetTabAndReloadTree}
									removeIcon="fa-ban"
									removeName={I('Reject')}
									// renderEdit={this.renderSuggestionEdit}
									savedId={savedId}
									showColumnCopy={false}
									showColumnRemove={true}
									showColumnVote={false}
									showMore={NEW_KB_GUI ? true : showMore.suggestion}
									tableColumnHeader={I('Name')}
									tableId="KnowledgeBaseSuggestionsTable"
									type={KBT_SUGGESTION}
									forErrand={this.props.forErrand}
								/>
							</div>
							<div className="kb-table-container col-md-12" style={tabs[4].contentStyle}>
								<KnowledgeBaseList
									allowCreateNew={false}
									deletedId={deletedId}
									onLoadList={onLoadList}
									edit={edit}
									editTitle={I('Review Rating')}
									list={tableList.ratingList}
									onChangeActiveTab={onChangeActiveTab}
									onCopyRow={this.handleCopyRow}
									onCreateNew={onCreateNew}
									onHandleEdit={onHandleEdit}
									onHandleRemove={onHandleRemove}
									onHandleSave={onHandleSave}
									onResetEdit={onResetEdit}
									// renderEdit={this.renderRatingEdit}
									savedId={savedId}
									showExtraColumn={false}
									showKeyboardShortcuts={false}
									showSearchOptions={false}
									showColumnCopy={false}
									showColumnRemove={false}
									showColumnVote={true}
									showMore={NEW_KB_GUI ? true : showMore.rating}
									tableColumnHeader={I('Name')}
									tableId="KnowledgeBaseRatingsTable"
									type={KBT_RATING}
									rating={true}
									onEditQuestionRating={onEditQuestionRating}
									forErrand={this.props.forErrand}
								/>
							</div>
						</div>
						<div className="list-form col-lg-6" style={editStyle}>
							<div className='knowledgebase-heading edit'>
								<div className='col-split-left'>
									<span className='admin-icons mb-0'>
										<i
											className='icon-edit'
										/>
									</span>
									<h5 className='d-inline'>{edit.title}</h5>
								</div>
								<div className='col-split-right'>
									{/* <TableIconicButton
										type='button'
										className='btn bg-transparent'
										iconClass='icon-times'
										title={I('Close form')}
										onClick={onClickClose}
									/> */}
									<span style={iconTimeStyle} onClick={this.handleCancel}>
										<i className='icon-times'></i>
									</span>

								</div>
							</div>
							{activeTab == 0 &&
								<KnowledgeBaseEdit
									title={edit.title}
									data={edit.data}
									handleSave={this.handleSave}
									onCancel={this.handleCancel} />}
							{activeTab == 1 &&
								<CategoryEdit
									title={edit.title}
									data={edit.data}
									handleSave={this.handleSave}
									onCancel={this.handleCancel} />}
							{activeTab == 2 &&
								<QuestionEdit
									name="libraryQuestion"
									noForBot={noForBotQuestion}
									title={edit.title}
									data={edit.data}
									showSave={true}
									handleSave={this.handleSave}
									onCancel={this.handleCancel}
									onDragnDropFile={this.props.onDragnDropFile}
									onAppendTemplate={onAppendTemplate}
									templates={templates}
									insertionText={insertionText}
									onResetInsertionText={onResetInsertionText}
									showKeyboardShortcuts={chatFeature}
									fileArchiveImages={this.props.archiveImgs} />}
							{activeTab == 3 &&
								<QuestionEdit
									name="librarySuggestion"
									noForBot={noForBotQuestion}
									title={edit.title}
									data={edit.data}
									showSave={false}
									showAccept={true}
									showReject={true}
									handleSave={this.handleSave}
									onReject={this.handleRemoveEdit}
									onCancel={this.handleCancel}
									onDragnDropFile={this.props.onDragnDropFile}
									fileArchiveImages={this.props.archiveImgs} />}
							{activeTab == 4 &&
								<RatingEdit
									name="libraryRating"
									data={edit.data}
									onCancel={this.handleCancel}
									onEditQuestionRating={onEditQuestionRating}
									title={edit.title} />}
						</div>
						<div className="kb-tree col-md-6 left" style={dropDownStyle}>
							<div className="kb-tree-dropdown">
								<DropDown
									fields={{ id: 'id', name: 'name' }}
									id="LibraryTreeRoot"
									items={treeRoots}
									multiSelect={false}
									name={I("Knowledge base")}
									nestedGroup="children"
									onChange={onChangeTreeRoot}
									ref="LibraryTreeRoot"
									selectedItems={selectedTreeRoot}
									textChooseItem={I("Choose Knowledge base")}
									textNoItem={I("No Knowledge Base")}
									textNoItemSelected={I("No Knowledge base selected")}
									textSelectedItem={I("Selected Knowledge base")}
									wantSelectAll={false}
									wantSelectNone={false}
									wantShowDone={false}
									knowledgeBase={true}
								/>
							</div>
							<KnowledgeBaseTree
								category={selectedTreeRootCategory}
								copyState={this.state.copyState}
								deletedId={deletedId}
								draggable={true}
								handleCategoryRemove={this.handleNodeRemove}
								handleCopyNode={this.handleCopyNode}
								handleDrop={this.handleDrop}
								onHandleEdit={onHandleEdit}
								handleLibraryRemove={this.handleNodeRemove}
								handlePaste={this.handlePaste}
								handleQuestionRemove={this.handleNodeRemove}
								insertedNode={insertedNode}
								library={selectedTreeRootLibrary}
								list={treeList}
								onDragStart={this.handleNodeDragStart}
								onFetchKnowledeBaseCategory={onFetchKnowledeBaseCategory}
								onFetchKnowledgeBase={onFetchKnowledgeBase}
								previewMaxHeight='350'
								removedNode={removedNode}
								savedId={savedId}
								showEdit={true}
								showRemove={true}
								showTimeControlled={true}
								handlePasteInto={this.handlePasteInto}
								edit={edit}
							/>
						</div>
					</div> : 
					<KnowledgeBaseNewWrapper
						areaDefaultLibrary={this.props.areaDefaultLibrary}
						forErrand={this.props.forErrand}
						popupMode={this.props.popupMode}
						edit={edit}
						onLoadList={onLoadList}
						tableList={tableList}
						showMore={NEW_KB_GUI}
						fetchingLibrary={fetchingLibrary}
						templates={templates}
						onChangeActiveTab={this.props.onChangeActiveTab}
						onLoadAgentTemplates={this.props.onLoadAgentTemplates}
						onAppendTemplate={onAppendTemplate}
						onLoadTreeRoots={this.props.onLoadTreeRoots}
						handleCopyRow={this.handleCopyRow}
						handleRemove={this.handleRemove}
						resetTabAndReloadTree={this.resetTabAndReloadTree}
						onHandleEdit={onHandleEdit}
						fileArchiveImages={this.props.archiveImgs}
						onDragnDropFile={this.props.onDragnDropFile}
						//for library selecting
						library={selectedTreeRootLibrary}
						treeList={treeList}
						onFetchKnowledgeBase={onFetchKnowledgeBase}
						onFetchKnowledeBaseCategory={onFetchKnowledeBaseCategory}
						showEdit={true}
						showRemove={true}
						showTimeControlled={true}
						showKeyboardShortcuts={chatFeature}
						onDragStart={this.handleNodeDragStart}
						onDragEnd={this.handleDropNewKB}
						onLibraryDragStart={this.onLibraryDragStart}
						onCategoryDragStart={this.onCategoryDragStart}
						onQuestionDragStart={this.onQuestionDragStart}
						onReject={this.handleRemoveEdit}
						chatbotList={this.props.chatbotsIdList}
						onLoadChatbotIntents={this.props.onLoadChatbotIntents}
						chatbotsIntents={this.props.chatbotsIntents}
						onCheckingChatbotIntentsForQuestion={this.props.onCheckingChatbotIntentsForQuestion}
						chatbotTrainingPhrases={this.props.chatbotTrainingPhrases}
						onAddTrainingPhrases={this.props.onAddTrainingPhrases}
						noForBotQuestion={noForBotQuestion}
						onRemoveTrainingPhrases={this.props.onRemoveTrainingPhrases}
						onRemoveNode={this.props.onRemoveNode}
						insertedNode={insertedNode}
						onHandleRemove={this.props.onHandleRemove}
						onInitiateAIAnswer={this.props.onInitiateAIAnswer}
						haveAIAnswerHistory={this.props.haveAIAnswerHistory}
						onMaximizeAIPanel={this.props.onMaximizeAIPanel}
						onLoadErrandPreview={this.props.onLoadErrandPreview}
						expandKnowledgeBasePanel={this.props.expandKnowledgeBasePanel}
						errandLibraryList={this.props.errandLibraryList}
						onGetKnowledgeBaseBySearch={this.props.onGetKnowledgeBaseBySearch}
						userVote={this.props.userVote}
						onUpdateUserVote={this.props.onUpdateUserVote}
						onSaveParentInfo={this.props.onSaveParentInfo}
						{...this.props}
					/>
					}
				</div>
			</StyledWrapper >
		)
	}
};

function arrayMove(arr, fromIndex, toIndex) {
	var element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
	return arr;
}

const FOLLOWUP_TEMPLATE_START = "[FOLLOWUP:";
const FOLLOWUP_TEMPLATE_END = ":FOLLOWUP]";

const emptyTemplateButton = {
	type: "postback",
	title: "",
	data: ""
}, emptyFollowupTemplate = {
	type: "button",
	text: "",
	buttons: [
		emptyTemplateButton
	]
};


//New modern UI component for date picker, to be moved to general component
const C3CalendarIcon = () => {
	return (
		<i className='icon-calendar-due' />
	)
}

const BasicDatePicker = ({input, onChange}) => {
	const [value, setValue] = useState(dayjs());
	useEffect(() => {
		if(input) {
			setValue(dayjs(input));
		}
	}, [input]);

	const handleChange = (newValue) => {
		const date = newValue ? newValue.format('YYYY/MM/DD') : null;
		setValue(newValue);
		onChange(date);
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				value={value}
				slots={{
					openPickerIcon: C3CalendarIcon,
				}}
				slotProps={{
					openPickerIcon: { fontSize: '12px', color: '#969696' },
				}}
				format="YYYY/MM/DD"
				onChange={(newDate) => handleChange(newDate)}
			/>
		</LocalizationProvider>
	);
}

function rgbToHex(red, green, blue) {
	const toHex = (value) => {
		const hex = value.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	};
	return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

function generateBackgroundColorArray(length) {
	const backgroundColors = [];
	for (let i = 0; i < length; i++) {
		const red = Math.floor(Math.random() * 256);
		const green = Math.floor(Math.random() * 256);
		const blue = Math.floor(Math.random() * 256);

		backgroundColors.push(rgbToHex(red, green, blue));
	}
	return backgroundColors;
}

export const KnowledgeBaseNewWrapper = React.memo(({
	forErrand = false,
	popupMode = false,
	areaDefaultLibrary,
	tableList,
	treeList,
	fetchingLibrary,
	edit,
	onLoadList,
	onDragStart,
	onDragEnd,
	chatbotList,
	onLoadChatbotIntents,
	chatbotsIntents,
	onCheckingChatbotIntentsForQuestion,
	chatbotTrainingPhrases,
	onAddTrainingPhrases,
	onRemoveTrainingPhrases,
	noForBotQuestion,
	insertedNode,
	onHandleRemove,
	onInitiateAIAnswer,
	haveAIAnswerHistory,
	onMaximizeAIPanel,
	onLoadErrandPreview,
	errandLibraryList,
	onGetKnowledgeBaseBySearch,
	userVote,
	onUpdateUserVote,
	...props
}) => {
	const [treeMode, setTreeMode] = useState(false);
	const [activeLibrary, setActiveLibrary] = useState(0);
	const [activeCategory, setActiveCategory] = useState(0);
	const [categoryClicked, setCategoryClicked] = useState(false);
	const [suggestionList, setSuggestionList] = useState([]);
	const [orderedCategoryList, setOrderedCategoryList] = useState([]);
	const [orderedQuestionList, setOrderedQuestionList] = useState([]);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [modalType, setModalType] = useState("");
	const [showQuestionInfo, setShowQuestionInfo] = useState(false);
	const [questionExpandMode, setQuestionExpandMode] = useState(false);
	const [showQuestionEditor, setShowQuestionEditor] = useState(false);
	const [searchSortOption, setSearchSortOption] = useState(0);
	const [kbColors, setKbColors] = useState([]);
	const [allChatbotIntents, setAllChatbotIntents] = useState([]);
	const DROP_ACTION_INSERT = "INSERT";
	const DROP_ACTION_REORDER = "REORDER";
	const [dropAction, setDropAction] = useState("");
	const [droppedInfo, setDroppedInfo] = useState({});

	const [showLibraryPanel, setShowLibraryPanel] = useState(true);
	const [showTreePanel, setShowTreePanel] = useState(true);
	const [showQuestionPanel, setShowQuestionPanel] = useState(true);
	const [popupBrowseMode, setPopupBrowseMode] = useState(false);
	const [searchedMode, setSearchedMode] = useState(false);

	useEffect(() => {
		if(forErrand) {
			const p = {
				offset: 0,
				limit: 100
			}
			onLoadList(KBT_LIBRARY, p, false);
		}
	}, []);

	useEffect(() => {
		if(forErrand) {
			const p = {};
			if(areaDefaultLibrary > 0) {
				if(props.onFetchKnowledgeBase) {
					props.onFetchKnowledgeBase(areaDefaultLibrary, p);
					setTreeMode(true);
					setActiveLibrary(areaDefaultLibrary);
					setShowLibraryPanel(false);
					setShowTreePanel(true);
				}
			} else {
				//if no default library for errand, show the first library
				if(errandLibraryList && errandLibraryList.length > 0) {
					const firstLibrary = errandLibraryList[0].id;
					if(props.onFetchKnowledgeBase) {
						props.onFetchKnowledgeBase(firstLibrary, p);
						setTreeMode(true);
						setActiveLibrary(firstLibrary);
						setShowLibraryPanel(false);
						setShowTreePanel(true);
					}
				} else {
					setShowLibraryPanel(true);
					setShowTreePanel(false);
				}
			}
			if(popupMode) {
				setShowQuestionPanel(true);
				setShowQuestionInfo(true);
				setPopupBrowseMode(true);
			} else {
				setShowQuestionPanel(false);
			}
		} else {
			setShowLibraryPanel(true);
			setShowTreePanel(true);
			setShowQuestionPanel(true);
		}
	}, [forErrand]);

	useEffect(() => {
		if(forErrand) {
			if(!props.ui.selectedCategory && !props.ui.selectedLibrary !== 0) {
				if(!popupMode) {
					setShowQuestionPanel(false);
				}
				setShowTreePanel(true);
			} else {
				if(props.ui.selectedCategory === 0 && props.ui.selectedLibrary) {
					if(!popupMode) {
						setShowQuestionPanel(false);
					}
					setShowTreePanel(true);
				}
			}
		}
	}, [props.ui.selectedCategory]);

	const boxShadowStyle = forErrand && !popupMode ? "none" : "0 0 5px rgba(0, 0, 0, 0.16)";

	const mainPanelStyle = {
		flex: 1,
		padding: !popupMode ? '8px 24px' : '0px 8px 24px 8px',
		boxShadow: boxShadowStyle,
		marginRight: '20px',
		display: showLibraryPanel ? 'block' : 'none',
	}
	const contentPanelStyle = {
		flex: 1,
		padding: !popupMode ? '2% 8px 24px 8px' : '0px 8px 24px 8px',
		borderRadius: '4px',
		bozShadow: boxShadowStyle,
		marginRight: '20px',
		display: showTreePanel ? 'block' : 'none',
		maxWidth: (!popupMode && forErrand) ? '100%' : 'unset',
	}
	const questionsPanelStyle = {
		flex: popupMode ? 2 : 1,
		backgroundColor: !popupMode ? '' : '',
		padding: '8px',
		maxWidth: (questionExpandMode || forErrand) ? '100%' : '35%',
		display: showQuestionPanel ? 'block' : 'none',
	}

	useEffect(() => {
		if(tableList.libraryList && tableList.libraryList.length > 0) {
			const colors = generateBackgroundColorArray(tableList.libraryList.length);
			setKbColors(colors);
		}
	}, [tableList.libraryList]);

	//fetch all available chatbots intent
	//to be match later with questions
	useEffect(() => {
		if(chatbotList && chatbotList.length > 0) {
			chatbotList.forEach((chatbotId) => {
				onLoadChatbotIntents(chatbotId);
			});
		}
		if(chatbotsIntents && chatbotsIntents.length > 0) {
			setAllChatbotIntents(chatbotsIntents);
		}
	}, [chatbotList, chatbotsIntents]);

	//search
	const performSearch = (type, searchTxt) => {
		let parameters = {};
		if(searchSortOption != 0) {
			parameters.option = searchSortOption;
		}
		parameters.search = searchTxt;
		parameters.offset = 0;
		parameters.limit = 100;

		const more = false;
		onLoadList(type, parameters, more);
	}

	const onSearchKnowledgeBase = (searchText, type) => {
		performSearch(type, searchText);
	}

	const [categoryPanelList, setCategoryPanelList] = useState(tableList.categoryList);
	const [secondColumnType, setSecondColumnType] = useState(KBT_CATEGORY);

	useEffect(() => {
		if(treeMode || forErrand) {
			let currentTree = [];
			if(treeList) {
				if (treeList[0] && treeList[0].list) {
					currentTree = treeList[0].list;
					if (!categoryClicked) {
						const categoryListArr = [];
						if (currentTree) {
							currentTree.forEach((item) => {
								if(item.type === KBT_CATEGORY && item.expanded === true) {
									setCategoryClicked(true);
									setActiveCategory(item.id);
									if(item.list && item.list.length > 0) {
										item.list.forEach((qItem) => {
											categoryListArr.push(qItem);
										});
									}
								} else {
									categoryListArr.push(item);
								}
							});
							if(!searchedMode) {
								setCategoryPanelList(categoryListArr);
								const orderedCategoryListArr = [];
								categoryListArr.forEach((item, index) => {
									const cItem = { ...item, order: index };
									orderedCategoryListArr.push(cItem);
								});
								setOrderedCategoryList(orderedCategoryListArr);
							}
						}
					} else if (categoryClicked) {
						const questionListArr = [];
						treeList[0].list.forEach((item) => {
							questionListArr.push(item);
						});
						if(!searchedMode) {
							setCategoryPanelList(questionListArr);
							//add 'order' in questionListArr for ordering purposes
							const orderedQuestionListArr = [];
							questionListArr.forEach((item, index) => {
								const qItem = { ...item, order: index };
								orderedQuestionListArr.push(qItem);
							});
							setOrderedQuestionList(orderedQuestionListArr);
						}
					} else {
						setCategoryPanelList(treeList[0].list);
					}
				}
			}
		}
	}, [treeList, activeLibrary]);

	const handleOpenKnowledgeBase = (id, type, param) => {
		setSearchedMode(false);
		setTreeMode(true);
		if(typeof type === "undefined" || type == KBT_LIBRARY) {
			if(props.onFetchKnowledgeBase) {
				props.onFetchKnowledgeBase(id, param);
				setActiveLibrary(id);
			}
			setCategoryClicked(false);
		} else if(type == KBT_CATEGORY) {
			setCategoryClicked(true);
			if(props.onFetchKnowledeBaseCategory) {
				props.onFetchKnowledeBaseCategory(id, param);
				setActiveCategory(id);
			}
		}
		if(forErrand) {
			if(type !== KBT_QUESTION) {
				setShowLibraryPanel(false);
				setShowTreePanel(true);
				setShowQuestionPanel(false);
				if(popupMode) {
					setShowQuestionPanel(true);
					setShowQuestionInfo(true);
				}
			}
		}
	}

	const handleOpenKnowledgeBaseFromContentPanel = (id, type, param) => {
		if(typeof type === "undefined" || type == KBT_LIBRARY) {
			if(props.onFetchKnowledgeBase) {
				props.onFetchKnowledgeBase(id, param);
			}
			setCategoryClicked(true);
		} else if(type == KBT_CATEGORY) {
			setCategoryClicked(true);
			if(props.onFetchKnowledeBaseCategory) {
				props.onFetchKnowledeBaseCategory(id, param);
				setActiveCategory(id);
			}
		}
		if(forErrand) {
			if(type !== KBT_QUESTION) {
				setShowLibraryPanel(false);
				setShowTreePanel(true);
				setShowQuestionPanel(false);
				if(popupMode) {
					setShowQuestionPanel(true);
					setShowQuestionInfo(true);
				}
			}
		}
	}

	const handleResetKnowledgeBaseTree = () => {
		setSearchedMode(false);
		if(forErrand) {
			setShowLibraryPanel(true);
			setShowTreePanel(false);
			setShowQuestionPanel(false);
			if(popupMode) {
				setShowQuestionPanel(true);
				setPopupBrowseMode(true);
			}

		} else {
			setTreeMode(false);
			setSuggestionList(tableList.suggestionList);
		}
		setActiveLibrary(0);
		setActiveCategory(0);
	}	
	const handleResetCategoryTree = (noFetch) => {
		setSearchedMode(false);
		setActiveCategory(0);
		setCategoryClicked(false);
		if(!noFetch) {
			if(props.onFetchKnowledgeBase) {
				const param = {};
				props.onFetchKnowledgeBase(activeLibrary, param);
			}
		}
	}

	const handleOpenQuestion = (id, param) => {
		setShowQuestionEditor(true);
	}

	const handleOnDragStart = (result) => {
	}

	const handleOnDragEnd = (result) => {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		let afterNodeId = 0, droppedItem, typeStr = "", draggableType = "", idStr = "", id = "", idx = 0;
		let containerType = KBT_LIBRARY, containerId = activeLibrary;
		let nodeInfo = {};
		const srcIdx = source.index;
		const destIdx = destination.index;

		typeStr = draggableId.split('_');
		draggableType = typeStr[1];

		if(treeMode) {
			const currentTree = treeList[0].list;
			if(activeCategory > 0) {
				//1.2 question (third panel) into category (middle panel).
				containerType = KBT_CATEGORY;
				containerId = activeCategory;
			}

			//these are case of valid drag and drop
			// 1) Insertions
			//1.1 question (third panel) into library (middle panel)

			if (destination.droppableId == "droppableBoxes_kb-content-panel" &&
				source.droppableId == "droppableBoxes_kb-questions-panel") {
				idStr = draggableId.match(/\d+$/);
				id = idStr ? parseInt(idStr[0]) : null;
				if(destination.index != 0) {
					idx = destination.index - 1;
					afterNodeId = currentTree[idx].nodeId;
				}

				//so now check if the case is question into library
				//dropped into library
				if(draggableType == KBT_QUESTION) {
					droppedItem = tableList.questionList.find((item) => item.id === id);
					if(droppedItem) {
						nodeInfo = {
							afterNodeId,
							itemId: droppedItem.id,
							itemType: KBT_QUESTION,
							containerId,
							containerType,
						}
						onDragEnd(nodeInfo);
					}
				}

				setDropAction(DROP_ACTION_INSERT);
			}
			//...
			// 2) Reordering
			//2.1 question (middle panel)
			else if (destination.droppableId == "droppableBoxes_kb-content-panel" &&
			source.droppableId == "droppableBoxes_kb-content-panel" && draggableType == KBT_QUESTION) {
				if(srcIdx > destIdx) {
					if(destIdx !== 0) {
						idx = destIdx - 1;
					}
				} else {
					if(destIdx !== 0) {
						idx = destIdx;
					}
				}
				if(idx == currentTree.length) {
					idx = currentTree.length - 1;
				}
				if(destIdx !== 0) {
					afterNodeId  = currentTree[idx].nodeId;
				} else {
					afterNodeId = 0;
				}
				idStr = draggableId.match(/\d+$/);
				id = idStr ? parseInt(idStr[0]) : null;

				if(draggableType == KBT_QUESTION) {
					//re order means items in current tree
					droppedItem = categoryPanelList.find((item) => item.nodeId === id);
					if(droppedItem) {
						nodeInfo = {
							currentNodeId: droppedItem.nodeId,
							currentContainerId: containerId,
							currentContainerType: containerType,
							afterNodeId,
							itemId: droppedItem.id,
							itemType: KBT_QUESTION,
							containerId,
							containerType,
						}
						onDragEnd(nodeInfo);
					}
				}
				setDropAction(DROP_ACTION_REORDER);
			} else if(destination.droppableId == "droppableBoxes_kb-content-panel" && draggableType == KBT_CATEGORY) {
				//...
				//2.2 category (middle panel)
				//...
				if(srcIdx > destIdx) {
					if(destIdx !== 0) {
						idx = destIdx - 1;
					}
				} else {
					if(destIdx !== 0) {
						idx = destIdx;
					}
				}
				if(idx == currentTree.length) {
					idx = currentTree.length - 1;
				}
				if(destIdx !== 0) {
					afterNodeId  = currentTree[idx].nodeId;
				} else {
					afterNodeId = 0;
				}

				idStr = draggableId.match(/\d+$/);
				id = idStr ? parseInt(idStr[0]) : null;
				//re order means items in current tree
				droppedItem = categoryPanelList.find((item) => item.nodeId === id);
				if(droppedItem) {
					nodeInfo = {
						currentNodeId: droppedItem.nodeId,
						currentContainerId: containerId,
						currentContainerType: containerType,
						afterNodeId,
						itemId: droppedItem.id,
						itemType: KBT_CATEGORY,
						containerId,
						containerType,
						reorder: true
					}
					onDragEnd(nodeInfo);
				}
				setDropAction(DROP_ACTION_REORDER);
			} else if(destination.droppableId === "droppableBoxes_kb-content-panel" && source.droppableId === "droppableBoxes_kb-main-panel") {
				//Drop another library into current library
				idx = destination.index - 1;
				idStr = draggableId.match(/\d+$/);
				containerId = activeLibrary;
				containerType = KBT_LIBRARY;
				id = idStr ? parseInt(idStr[0]) : null;

				//only allow drop when id (sub library) is not same as the container id (parent library)
				if(id !== containerId) {
					//FIXME: need to validate the circular reference as well, currently the child of the source node
					//is not loaded yet hence not possible to validate
					if(draggableType == KBT_LIBRARY) {
						if(id) {
							nodeInfo = {
								afterNodeId: 0,
								itemId: id,
								itemType: KBT_LIBRARY,
								containerId,
								containerType,
							}
							onDragEnd(nodeInfo);
						}
					}
					setDropAction(DROP_ACTION_INSERT);
				} else {
					console.log("ERROR : Same library insertion not allowed");
				}
			}
		} else {
			//Non tree mode
			//category/question into library insertion only
			//1) category -> library
			if(destination.droppableId === "droppableBoxes_kb-main-panel" && source.droppableId === "droppableBoxes_kb-content-panel") {
				idx = destination.index - 1;
				const libList = tableList.libraryList;
				containerId = libList[idx].id;
				containerType = KBT_LIBRARY;
				idStr = draggableId.match(/\d+$/);
				id = idStr ? parseInt(idStr[0]) : null;
				if(draggableType == KBT_CATEGORY) {
					if(id) {
						nodeInfo = {
							afterNodeId: 0,
							itemId: id,
							itemType: KBT_CATEGORY,
							containerId,
							containerType,
						}
						onDragEnd(nodeInfo);
					}
				}


			}
			//2) question -> library
			else if(destination.droppableId === "droppableBoxes_kb-main-panel" && source.droppableId === "droppableBoxes_kb-questions-panel") {
				idx = destination.index - 1;
				const libList = tableList.libraryList;
				containerId = libList[idx].id;
				containerType = KBT_LIBRARY;
				idStr = draggableId.match(/\d+$/);
				id = idStr ? parseInt(idStr[0]) : null;
				if(draggableType == KBT_QUESTION) {
					if(id) {
						nodeInfo = {
							afterNodeId: 0,
							itemId: id,
							itemType: KBT_QUESTION,
							containerId,
							containerType,
						}
						onDragEnd(nodeInfo);
					}
				}
			}
			setDropAction(DROP_ACTION_INSERT);
		}
		if(droppedItem) {
			if(!treeMode && destination.droppableId === "droppableBoxes_kb-main-panel") {
				droppedItem.containerType = KBT_LIBRARY;
			}
			droppedItem.targetIndex = destIdx;
			droppedItem.sourceIndex = srcIdx;
			droppedItem.type = draggableType;
			setDroppedInfo(droppedItem);
		}
	}

	//update tree GUI efficiently without re-fetching
	//base on the updated/response node Id
	useEffect(() => {
		if(insertedNode) {
			let updatedCategoryPanelList = [...categoryPanelList];
			if(dropAction === DROP_ACTION_REORDER) {
				updatedCategoryPanelList = moveObjectInArray(updatedCategoryPanelList, droppedInfo.sourceIndex, droppedInfo.targetIndex);
				setCategoryPanelList(updatedCategoryPanelList);
			} else {
				if(droppedInfo && droppedInfo.containerType !== KBT_LIBRARY) {
					if(droppedInfo.nodeId) {
						droppedInfo.nodeId = insertedNode.nodeId;
						updatedCategoryPanelList.splice(droppedInfo.targetIndex, 0, droppedInfo);
						setCategoryPanelList(updatedCategoryPanelList);
					} else {
						console.log("no valid drop info. added by modal");
						//re fetching tree
						const param = {};
						if(activeCategory > 0) {
							props.onFetchKnowledeBaseCategory(activeCategory, param);
						} else {
							props.onFetchKnowledgeBase(activeLibrary, param);
						}
					}
				}
			}
		}
	}
	, [insertedNode]);

	useEffect(() => {
		if(tableList && tableList.suggestionList && tableList.suggestionList.length > 0) {
			setSuggestionList(tableList.suggestionList);
		}
	}, [tableList]);

	useEffect(() => {
		if(!forErrand) {
			let cpl = [], sct = KBT_CATEGORY;
			if((treeMode || forErrand) && activeCategory == 0) {
				if(activeLibrary > 0) {
					cpl = orderedCategoryList;
				}
			} else if(treeMode && activeCategory > 0) {
				cpl = orderedQuestionList;
				sct = KBT_CATEGORY;
			} else {
				cpl = tableList.categoryList;
			}
			setCategoryPanelList(cpl);
			setSecondColumnType(sct);
		}
	}, [tableList, treeMode, activeLibrary]);

	const onHideUpdateModal = () => {
		setShowUpdateModal(false);
		setModalType("");
	}

	const onEditKnowledgeBase = (id, type) => {
		if(type === KBT_LIBRARY || type === KBT_CATEGORY) {
			setShowUpdateModal(true);
			setModalType(type);
			
		} else {
			if(id !== 0) {
				setShowQuestionInfo(true);
				if(forErrand) {
					setShowLibraryPanel(false);
					setShowQuestionPanel(true);
					if(popupMode) {
						setShowTreePanel(true);
					} else {
						setShowTreePanel(false);
					}
				}
			}
		}
		if(id === 0) {
			props.onCreateNew(I("Create new"));
			if(type === "KBT_QUESTION_ALL") {
				handleOpenQuestion(id);
				setShowQuestionInfo(true);
			}
		} else {
			props.onHandleEdit(type, id, activeLibrary, forErrand);
		}
	}

	const handleSave = (type, data) => {
		props.onHandleSave(type, data);
		setShowUpdateModal(false);
		setModalType("");
	}

	const handleExpandQuestionInfo = (toggle) => {
		setQuestionExpandMode(toggle);
	}

	const [showAIPanel, setShowAIPanel] = useState(false);

	const handleShowAIPanel = (toggle, answer) => {
		setShowAIPanel(toggle);
		onInitiateAIAnswer(answer, haveAIAnswerHistory, activeLibrary);
	}
	const handleMaximizeAIPanel = () => {
		onMaximizeAIPanel();
	}
	const handleCloseAIPanel = () => {
		setShowAIPanel(false);
	}

	const [browseSearchMode, setBrowseSearchMode] = useState(false);
	const handleLoadSearchInLibrary = () => {
		setBrowseSearchMode(true);
	}

	useEffect(() => {
		if(forErrand && searchedMode) {
			if(treeList && treeList.length > 0) {
				const allQuestions = findAllQuestions(treeList[0]);
				setCategoryPanelList(allQuestions);
			}
		}
	}, [treeList]);

	const handleGetKnowledgeBaseBySearch = (id, param) => {
		setSearchedMode(true);
		onGetKnowledgeBaseBySearch(id, param);
	}

	const handleExitSearchMode = () => {
		setBrowseSearchMode(false);
		handleOpenKnowledgeBase(activeLibrary, KBT_LIBRARY);
	}

	const disableDrag = forErrand ? true : false;
	const customWrapperStyle = {
		padding: popupMode ? '2px' : '0'
	}
	return (
		<div>
			<DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
				<div id="DragDropKanban"
					className="dragDrop-context knowledge-base" style={customWrapperStyle}>

					<KBLibrary
						key="kb-main-panel"
						panelId="kb-main-panel"
						forErrand={forErrand}
						popupMode={popupMode}
						kbColors={kbColors}
						nodeIdentifier={'id'}
						edit={edit}
						activeLibrary={activeLibrary}
						type={KBT_LIBRARY}
						show={!questionExpandMode}
						treeMode={treeMode}
						showMore={props.showMore}
						list={tableList.libraryList}
						treeList={treeList}
						onLoadAgentTemplates={props.onLoadAgentTemplates}
						onLoadTreeRoots={props.onLoadTreeRoots}
						onChangeActiveTab={props.onChangeActiveTab}
						onClickKB={handleOpenKnowledgeBase}
						onResetKnowledgeBase={handleResetKnowledgeBaseTree}
						customStyle={mainPanelStyle}
						disableDrag={treeMode ? false : true || disableDrag}
						onBoxDragStart={props.onLibraryDragStart}
						onSearch={onSearchKnowledgeBase}
						onEditKnowledgeBase={onEditKnowledgeBase}
						onExpandQuestionInfo={handleExpandQuestionInfo}
						onLoadList={onLoadList}
						onRemoveNode={props.onRemoveNode}
						{...props}
					/>
					<KBLibrary
						key="kb-content-panel"
						panelId="kb-content-panel"
						forErrand={forErrand}
						popupMode={popupMode}
						kbColors={kbColors}
						nodeIdentifier={treeMode ? 'nodeId' : 'id'}
						edit={edit}
						activeLibrary={activeLibrary}
						activeCategory={activeCategory}
						type={secondColumnType}
						show={!questionExpandMode}
						treeMode={treeMode}
						showMore={props.showMore}
						fetchingLibrary={fetchingLibrary}
						list={categoryPanelList}
						treeList={treeList}
						allLibraryList={tableList.libraryList}
						allCategoryList={tableList.categoryList}
						onLoadAgentTemplates={props.onLoadAgentTemplates}
						onLoadTreeRoots={props.onLoadTreeRoots}
						onChangeActiveTab={props.onChangeActiveTab}
						onClickKB={handleOpenKnowledgeBaseFromContentPanel}
						onResetCategory={handleResetCategoryTree}
						customStyle={contentPanelStyle}
						disableDrag={disableDrag}
						onBoxDragStart={props.onCategoryDragStart}
						onSearch={onSearchKnowledgeBase}
						onEditKnowledgeBase={onEditKnowledgeBase}
						onExpandQuestionInfo={handleExpandQuestionInfo}
						onLoadList={onLoadList}
						onRemoveNode={props.onRemoveNode}
						onResetKnowledgeBase={handleResetKnowledgeBaseTree}
						onLoadSearchInLibrary={handleLoadSearchInLibrary}
						onGetKnowledgeBaseBySearch={handleGetKnowledgeBaseBySearch}
						onExitSearchMode={handleExitSearchMode}
						{...props}
					/>

					<KBLibrary
						key="kb-questions-panel"
						panelId="kb-questions-panel"
						forErrand={forErrand}
						popupMode={popupMode}
						nodeIdentifier={treeMode ? 'nodeId' : 'id'}
						edit={edit}
						activeLibrary={activeLibrary}
						type={"KBT_QUESTION_ALL"}
						treeMode={treeMode}
						showQuestionInfo={showQuestionInfo}
						popupBrowseMode={popupBrowseMode}
						showQuestionEditor={showQuestionEditor}
						showMore={props.showMore}
						list={tableList.questionList}
						sugList={tableList.suggestionList}
						allQuestionList={tableList.questionList}
						suggestionList={suggestionList}
						treeList={treeList}
						templates={props.templates}
						fileArchiveImages={props.fileArchiveImages}
						onLoadAgentTemplates={props.onLoadAgentTemplates}
						onAppendTemplate={props.onAppendTemplate}
						onDragnDropFile={props.onDragnDropFile}
						onLoadTreeRoots={props.onLoadTreeRoots}
						onChangeActiveTab={props.onChangeActiveTab}
						onClickKB={handleOpenQuestion}
						customStyle={questionsPanelStyle}
						disableDrag={treeMode ? false : true}
						onBoxDragStart={props.onQuestionDragStart}
						onSearch={onSearchKnowledgeBase}
						onEditKnowledgeBase={onEditKnowledgeBase}
						onCloseQuestionInfo={() => setShowQuestionInfo(false)}
						onExpandQuestionInfo={handleExpandQuestionInfo}
						onReject={props.onReject}
						onLoadList={onLoadList}
						onSetSortOption={(sort) => setSearchSortOption(sort)}
						chatbotList={chatbotList}
						chatbotsIntents={chatbotsIntents}
						allChatbotIntents={allChatbotIntents}
						onCheckingChatbotIntentsForQuestion={onCheckingChatbotIntentsForQuestion}
						chatbotTrainingPhrases={chatbotTrainingPhrases}
						onAddTrainingPhrases={onAddTrainingPhrases}
						noForBotQuestion={noForBotQuestion}
						onRemoveTrainingPhrases={onRemoveTrainingPhrases}
						onRemoveNode={props.onRemoveNode}
						onHandleRemove={onHandleRemove}
						onShowAIPanel={handleShowAIPanel}
						onLoadErrandPreview={onLoadErrandPreview}
						browseSearchMode={browseSearchMode}
						userVote={userVote}
						onUpdateUserVote={onUpdateUserVote}
						{...props}
					/>
					{
						!forErrand ?
							<AISidePanels
							showAIAnswerPanel={showAIPanel}
							onMaximizePanel={handleMaximizeAIPanel}
							onCloseAIAnswerPanel={handleCloseAIPanel}
							className="kb-ai-panel"
						/> : null
					}
				</div>
			</DragDropContext>
			{
				(!forErrand && showUpdateModal) &&
				<KBUpdateModal
					show={showUpdateModal}
					treeMode={treeMode}
					type={modalType}
					dataSet={edit.data}
					activeLibrary={activeLibrary}
					activeCategory={activeCategory}
					libraryList={tableList.libraryList}
					categoryList={tableList.categoryList}
					treeList={treeList}
					onDelete={onHandleRemove}
					onSave={handleSave}
					onClose={onHideUpdateModal}
				/>
			}
			{
				forErrand && <ChatbotsTrainPhraseSelection />
			}
	</div>
	)
});

KnowledgeBaseNewWrapper.displayName = 'KnowledgeBaseNewWrapper';

const ExpandableDiv = React.memo(({ expand, children }) => {
	return (
	  <div className={`kb-expandable-area ${expand ? 'expand' : ''}`}>
		{children}
	  </div>
	);
  });
  

const FormInputWrapper = ({children, className = ""}) => {
	const style = {padding: '8px 0px', margin: '8px 0px', display: 'flex', gap: '8px'};
	return (
		<div style={style} className={className}>
			{children}
		</div>
	)
}
const FormHeader = ({text}) => {
	return (
		<span style={{fontWeight: '700', fontSize: '14px', lineHeight: '20px', display: 'block', color: '#6d6d6d'}}>{text}</span>
	)
}

const KBLibrary = React.memo(({
	panelId,
	readOnly = false,
	forErrand =false,
	popupMode = false,
	nodeIdentifier,
	type,
	show = true,
	edit,
	activeLibrary,
	activeCategory,
	list,
	sugList,
	kbColors,
	templates,
	fileArchiveImages,
	suggestionList = [],
	showQuestionInfo = false,
	popupBrowseMode = false,
	showQuestionEditor = false,
	onCloseQuestionInfo,
	onExpandQuestionInfo,
	onReject,
	onSetSortOption,
	treeList, disableDrag, showMore, customStyle, onLoadAgentTemplates, onAppendTemplate, onLoadTreeRoots,
	onBoxDragStart, onChangeActiveTab, onEditKnowledgeBase, onDragnDropFile,
	onResetKnowledgeBase, onResetCategory,
	onClickKB,
	fetchingLibrary,
	chatbotList,
	chatbotsIntents,
	allChatbotIntents,
	onCheckingChatbotIntentsForQuestion,
	chatbotTrainingPhrases,
	onAddTrainingPhrases,
	onRemoveTrainingPhrases,
	noForBotQuestion,
	onRemoveNode,
	onHandleRemove,
	onShowAIPanel,
	onLoadErrandPreview,
	onLoadSearchInLibrary,
	onGetKnowledgeBaseBySearch,
	browseSearchMode,
	onExitSearchMode,
	userVote,
	onUpdateUserVote,
	...props
}) => {
	const [activeTab, setActiveTab] = useState(0);
	const [kbType, setKbType] = useState(type);
	const [expandQuestionInfo, setExpandQuestionInfo] = useState(false);
	const [editQuestionMode, setEditQuestionMode] = useState(false);
	useEffect(() => {
		setKbType(type);
	}, [type]);

	const handleTabClick = (event) => {
		const tab = parseInt(event.target.parentNode.id.substring(11));
		setActiveTab(tab);
		let parameters = {};
		const more = false;
		parameters.offset = 0;
		parameters.limit = 100;
		if(tab == 1) {	
			setKbType(KBT_SUGGESTION);
			props.onLoadList(KBT_SUGGESTION, parameters, more);
		} else {
			setKbType("KBT_QUESTION_ALL");
		}
	};

	const tabs = [], numTabs = 2;
	for (var i = 0; i < numTabs; i++) {
		var tabClass = '';
		var contentStyle = { display: 'none' };
		if (i == activeTab) {
			tabClass = 'active';
			contentStyle = {};
		}
		tabs.push({ tabClass: tabClass, contentStyle: contentStyle });
	};

	const handleSettings = (id, type) => {
		onEditKnowledgeBase(id, type);
	}

	const onHandleLoadErrandPreview = (errandId, threadId) => {
		if(onLoadErrandPreview) {
			onLoadErrandPreview(errandId, threadId);
		}
	}

	const [searchText, setSearchText] = useState("");
	const [searchOption, setSearchOption] = useState(0);

	const [showTopSearchBar, setShowTopSearchBar] = useState(false);
	const [showSearchPlaceholder, setShowSearchPlaceholder] = useState(false);
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [showCloseSearchIcon, setShowCloseSearchIcon] = useState(false);

	useEffect(() => {
		if(!forErrand && (type === KBT_LIBRARY || type === "KBT_QUESTION_ALL") && !showQuestionInfo) {
			setShowTopSearchBar(true);
		} else {
			setShowTopSearchBar(false);
		}
	}, [type, showQuestionInfo]);

	//to load search on tree panel
	const handleLoadSearchInLibrary = () => {
		setShowTopSearchBar(true);
		setShowSearchPlaceholder(true);
		setShowCloseSearchIcon(true);
		onLoadSearchInLibrary();
	}

	const handleSearchTextChange = (event) => {
		setSearchText(event.target.value);
	}

	const handleSearchOptionChange = (sort) => {
		setSearchOption(sort);
		reloadQuestionList(sort);
		onSetSortOption(sort);
	}

	const showSearchOptions = type == "KBT_QUESTION_ALL" && activeTab != 1 ? true : false;
	let searchOptions = "";
	if (showSearchOptions) {
		let items = [
			{ id: 0, name: I('Sort by') },
			{ id: 1, name: I('Used') },
			{ id: 2, name: I('Unused') },
			{ id: 3, name: I('Time controlled') },
			{ id: 4, name: I('Top list') }
		];
		if (props.showKeyboardShortcuts) {
			items.push({ id: 5, name: I('Keyboard shortcut') });
		}

		searchOptions = (
			<SelectInputRow
				id={"kb-search-opt"}
				name={"kbSearchOption"}
				className={"admin-select kb-search-opt"}
				option={items}
				value={searchOption}
				onSelect={handleSearchOptionChange}
				readonly={false}
			/>
		)
	}

	const handleSearchKeyDown = (event) => {
		if(event.keyCode == 13) {
			event.preventDefault();
			let searchType = type;
			if(type == "KBT_QUESTION_ALL") {
				searchType = activeTab == 1 ? KBT_SUGGESTION : KBT_QUESTION;
			} else if (type == KBT_CATEGORY) {
				searchType = KBT_QUESTION;
				setShowSearchResult(true);
				setShowSearchPlaceholder(false);
			}
			if(!forErrand) {
				props.onSearch(searchText, searchType);
			} else {
				if(onGetKnowledgeBaseBySearch) {
					const param = {
						search: searchText,
						source: 0
					};
					onGetKnowledgeBaseBySearch(activeLibrary, param);
				}
			}
		}
	}

	const handleCloseSearchPanel = () => {
		setShowTopSearchBar(false);
		setShowSearchResult(false);
		setShowCloseSearchIcon(false);
		setSearchText("");
		setSearchOption(0);
		setShowSearchPlaceholder(false);
		if(onExitSearchMode) {
			onExitSearchMode();
		}
	}

	const searchResultsElement = (
		<div key="kb-search-results">
			<span></span>
		</div>
	);

	const noResultsElement = (
		<span style={{margin: '0px 35px'}}>{I("No results found for")} <strong>"{searchText}"</strong></span>
	);

	const searchResultsSpan = (
		<span style={{margin: '0px 35px'}}>{I("Search results for ")} <strong>"{searchText}"</strong></span>
	);

	const listComponent = <KnowledgeBaseList
		key={kbType+"-list-"+activeTab}
		droppableId={panelId}
		forErrand={forErrand}
		popupMode={popupMode}
		nodeIdentifier={nodeIdentifier}
		createTitle={I('Add new')}
		deletedId={props.deletedId}
		draggable={true}
		edit={edit}
		editTitle={I('Edit')}
		list={type == "KBT_QUESTION_ALL" ? activeTab == 1 ? suggestionList : list : list}
		kbColors={kbColors}
		onCopyRow={props.handleCopyRow}
		onCreateNew={props.onCreateNew}
		onDragStart={props.onLibraryDragStart}
		onBoxDragStart={onBoxDragStart}
		onHandleEdit={props.onHandleEdit}
		onHandleRemove={props.onHandleRemove}
		onHandleSave={props.onHandleSave}
		onRemove={props.handleRemove}
		onResetEdit={props.onResetEdit}
		onLoadList={props.onLoadList}
		resetTabAndReloadTree={props.resetTabAndReloadTree}
		savedId={props.savedId}
		showPreview={true}
		showColumnCopy={true}
		showColumnRemove={true}
		showColumnVote={false}
		showMore={showMore.library}
		tableColumnHeader={I('Name')}
		tableId="KnowledgeBaseLibrariesTable"
		type={type}
		treeMode={props.treeMode}
		knowledgeBase={true}
		new={cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")}
		activeLibrary={activeLibrary}
		activeCategory={activeCategory}
		treeList={treeList}
		fetchingLibrary={fetchingLibrary}
		disableDrag={disableDrag}
		allLibraryList={props.allLibraryList}
		allCategoryList={props.allCategoryList}
		allQuestionList={props.allQuestionList}
		kbType={kbType}
		onFetchKnowledgeBase={onClickKB}
		onResetKnowledgeBase={onResetKnowledgeBase}
		onResetCategory={onResetCategory}
		onEditKnowledgeBase={handleSettings}
		suggestMode={type == "KBT_QUESTION_ALL" ? activeTab == 1 ? true : false : false}
		onRemoveNode={onRemoveNode}
		onClickErrandId={onHandleLoadErrandPreview}
		onShowAnswerForErrand={props.onShowAnswerForErrand}
		onLoadSearchInLibrary={handleLoadSearchInLibrary}
		showSearchResult={showSearchResult}
		onSaveParentInfo={props.onSaveParentInfo}
	/>;
	let KBElems = [];
	if(type == "KBT_QUESTION_ALL" && !showQuestionInfo) {
		KBElems.push(<div key={"question-tab"+activeTab}>
			<ul className="nav nav-tabs">
				<li role="presentation" id={"libraryTab_0"} className="nav-item" data-qa-id="QA_tab_questions">
					<a className={`nav-link ${tabs[0].tabClass}`} href="#"
						onClick={handleTabClick}>{I('Questions')}
					</a>
				</li>
				<li role="presentation" id={"libraryTab_1"} className="nav-item" data-qa-id="QA_tab_suggestions">
					<a className={`nav-link ${tabs[1].tabClass}`} href="#"
						onClick={handleTabClick}>{I('Suggestions')}
					</a>
				</li>
			</ul>
			<div className="kb-table-container" style={tabs[0].contentStyle}>
				{
					activeTab == 0 ? listComponent : null
				}
			</div>
			<div className="kb-table-container" style={tabs[1].contentStyle}>
				{
					activeTab == 1 ? listComponent : null
				}
			</div>
		</div>);
	} else {
		if (showSearchPlaceholder) {
			KBElems.push(searchResultsElement);
		} else if (!showQuestionInfo || showSearchResult) {
			KBElems.push(
				<div key="kb-lists">
					{showSearchResult && list.length === 0 ? noResultsElement : ""}
					{showSearchResult && list.length > 0 ? searchResultsSpan : ""}
					{listComponent}
				</div>
			);
		}
	}

	const reloadQuestionList = (sort) => {
		let parameters = {};
		parameters.offset = 0;
		parameters.limit = 100;
		if(sort !== 0) {
			parameters.option = sort;
		}
		const more = false;
		//todo how to implement offset/pagination on this component
		if(type == "KBT_QUESTION_ALL") {
			props.onLoadList(KBT_QUESTION, parameters, more);
		}
	}

	const activeButton = false;
	const handleEdit = (editId, type) => {
		props.onHandleEdit(editId, type);
		onEditKnowledgeBase(editId, type);
	}

	if(!show) {
		customStyle.display = 'none';
	}

	let hideAddNewBtn = false;
	if((editQuestionMode && type === "KBT_QUESTION_ALL") || (activeTab == 1)) {
		hideAddNewBtn = true;
	}

	let showBrowseTitleOnly = false;
	let browseTitle = I("Browse knowledge base");
	let browseSubTitle = I("Choose a knowledge base to get started");
	if(popupBrowseMode && showQuestionInfo) {
		if(browseSearchMode) {
			browseTitle = I("Search in knowledge base");
			browseSubTitle = I("Type keywords to get started");
		}
		if(edit && edit.data && edit.data.id > 0) {
			showBrowseTitleOnly = false;
		} else {
			showBrowseTitleOnly = true;
		}
	}

	return (
		<div id={"kb-library-panel"} style={customStyle}>
			{
				showBrowseTitleOnly ?
					<div className="kb-ready-text">
						<h1>{browseTitle}</h1>
						<h2 className="book">{browseSubTitle}</h2>
					</div>
					: <Fragment>
						{
							(!forErrand && ((type === KBT_LIBRARY || type === "KBT_QUESTION_ALL") && !expandQuestionInfo)) &&
							<Fragment>
								<TableHeader
									hideAddIcon
									title={type === KBT_LIBRARY ? I("Knowledge bases") : activeTab == 0 ? I("Questions") : I("Suggestions")}
								/>
								{
									type === KBT_LIBRARY &&
									<Helper>{I("A collection of articles, guides, FAQs, and other resources that provide answers to common questions and problems that your customers or agents may have.")}</Helper>
								}
							</Fragment>
						}
						{!forErrand &&
							<div style={{ textAlign: 'right', visibility: hideAddNewBtn ? 'hidden' : 'visible' }} >
								<TableIconicButton
									type='button'
									className={'btn bg-transparent btn-round ' + activeButton}
									iconClass='icon-add'
									title={I('Create new')}
									onClick={() => handleEdit(0, type)}
								/>
							</div>
						}
						{showTopSearchBar &&
							<div className='knowledgeBaseSearchWrapper'>
								<TextInputRow
									id="QA_knowledgebase_search_input"
									name="agentSearchBox knowledgeBaseSearchBox"
									className="form-control agentSearchBox input-sm"
									icon="icon-search"
									placeholder={I("Search")}
									value={searchText}
									onChange={handleSearchTextChange}
									onKeyDown={handleSearchKeyDown}
								/>
								{
									showCloseSearchIcon && <span className='kb-close-search-icon' onClick={handleCloseSearchPanel}><i className='icon-times'></i></span>
								}
								{searchOptions}
							</div>
						}
						{KBElems}
						{
							showQuestionInfo &&
							<KBExpandableDiv
								type={type}
								forErrand={forErrand}
								popupMode={popupMode}
								edit={edit}
								list={sugList}
								insertionText={props.insertionText}
								templates={templates}
								activeTab={activeTab}
								showKeyboardShortcuts={props.showKeyboardShortcuts}
								fileArchiveImages={fileArchiveImages}
								onAppendTemplate={onAppendTemplate}
								onDragnDropFile={onDragnDropFile}
								expandQuestionInfo={expandQuestionInfo}
								onQuestionEdit={(edit) => setEditQuestionMode(edit)}
								onSetExpandQuestionInfo={setExpandQuestionInfo}
								showQuestionEditor={showQuestionEditor}
								onCloseQuestionInfo={onCloseQuestionInfo}
								onExpandQuestionInfo={onExpandQuestionInfo}
								onReject={onReject}
								onResetInsertionText={props.onResetInsertionText}
								onHandleSave={props.onHandleSave}
								chatbotList={chatbotList}
								chatbotsIntents={chatbotsIntents}
								allChatbotIntents={allChatbotIntents}
								onCheckingChatbotIntentsForQuestion={onCheckingChatbotIntentsForQuestion}
								chatbotTrainingPhrases={chatbotTrainingPhrases}
								onAddTrainingPhrases={onAddTrainingPhrases}
								onRemoveTrainingPhrases={onRemoveTrainingPhrases}
								onHandleRemove={onHandleRemove}
								onLoadList={props.onLoadList}
								onShowAIPanel={onShowAIPanel}
								chat={props.chat}
								ui={props.ui}
								currentReply={props.currentReply}
								onShowAnswerForErrand={props.onShowAnswerForErrand}
								noForBotQuestion={noForBotQuestion}
								handleAppendKnowledgeBase={props.handleAppendKnowledgeBase}
								onRequestTrainChatbots={props.onRequestTrainChatbots}
								userVote={userVote}
								onUpdateUserVote={onUpdateUserVote}
								onToggleRewritePanelAnswer={props.onToggleRewritePanelAnswer}
							/>
						}
					</Fragment>
			}
		</div>
	)
});

const TextWithBg = styled.div`
	padding: 0px 4px;
	background: #f8f8f8;
	font-size: 12px;
	font-weight: 400;
	color: #6d6d6d;
`;

function isObjEmpty (obj) {
    return Object.keys(obj).length === 0;
}

KBLibrary.displayName = 'KBLibrary';

const KBExpandableDiv = React.memo(({
	type,
	edit,
	list,
	forErrand = false,
	popupMode = false,
	templates,
	insertionText,
	expandQuestionInfo,
	showQuestionEditor,
	fileArchiveImages,
	onAppendTemplate,
	onDragnDropFile,
	onExpandQuestionInfo,
	onSetExpandQuestionInfo,
	onCloseQuestionInfo,
	onReject,
	activeTab,
	onHandleSave,
	onResetInsertionText,
	showKeyboardShortcuts,
	onQuestionEdit,
	chatbotList,
	chatbotsIntents,
	allChatbotIntents,
	onCheckingChatbotIntentsForQuestion,
	chatbotTrainingPhrases,
	onAddTrainingPhrases,
	onRemoveTrainingPhrases,
	onHandleRemove,
	onLoadList,
	onShowAIPanel,
	chat,
	ui,
	currentReply,
	noForBotQuestion,
	handleAppendKnowledgeBase,
	onRequestTrainChatbots,
	userVote,
	onUpdateUserVote,
	onToggleRewritePanelAnswer
}) => {
	const [questionParams, setQuestionParams] = useState({});
	const [readableAnswer, setReadableAnswer] = useState("");

	const [genTemplate, setGenTemplate] = useState(false);
	const [templateData, setTemplateData] = useState(emptyFollowupTemplate);

	const [createNew, setCreateNew] = useState(false);
	const [editorMode, setEditorMode] = useState(false);
	const [agentName, setAgentName] = useState("");

	const [activeQuestionTab, setActiveQuestionTab] = useState(0);
	const [listOfChatBotTexts, setListOfChatBotTexts] = useState([]);

	const [trainingPhrasesParam, setTrainingPhrasesParam] = useState({});
	const [trainingPhrasesToBeRemoved, setTrainingPhrasesToBeRemoved] = useState([]);
	const resetParams = () => {
		const qParams = {};
		qParams.id = 0;
		qParams.subject = "";
		qParams.question = "";
		qParams.answer = "";
		qParams.attachments = [];
		qParams.voteUp = 0;
		qParams.voteDown = 0;
		qParams.userComment = "";
		qParams.external = false;
		qParams.forBot = false;
		qParams.showInTopList = false;
		qParams.timeControlled = false;
		qParams.startDate = "";
		qParams.endDate = "";
		qParams.keyboardShortcut = "";
		qParams.keyboardShortcutUseAlt = false;
		qParams.keyboardShortcutUseCtrl = false;
		qParams.keyboardShortcutUseMeta = false;
		qParams.keyboardShortcutUseShift = false;
		qParams.chatbots = [];
		setQuestionParams(qParams);
		setReadableAnswer("");
		setGenTemplate(false);
		setTemplateData(emptyFollowupTemplate);
	}

	const autoScroll = (element) => {
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
		}
	}

	useEffect(() => {
		resetParams();
		autoScroll(document.querySelector('.kb-expandable-area'));
		return () => {
			setListOfChatBotTexts([]);
		}
	}, []);

	const { id: qid, name: subject, question, attachments, userComment, external,
		forBot, showInTopList, timeControlled, startDate, endDate, keyboardShortcut, keyboardShortcutUseAlt,
		keyboardShortcutUseCtrl, keyboardShortcutUseMeta, keyboardShortcutUseShift, externalVotes, voteDown, voteUp
	} = edit.data;
	const [ votedOnce, setVotedOnce ] = useState(false);
	const [ votedUp, setVotedUp ] = useState(false);
	const [ votedDown, setVotedDown ] = useState(false);
	const [ showCommentInput, setShowCommentInput ] = useState(false);
	const [ comment, setComment ] = useState(userVote.comment ? userVote.comment : "");
	const [ commentReadOnly, setCommentReadOnly] = useState(true);
	const [ disableRating, setDisableRating ] = useState(false);

	const [ currentVote, setCurrentVote ] = useState(0);
	const [ currentCommentsCount, setCurrentCommentsCount ] = useState(0);
	useEffect(() => {
		if (activeTab === 1 && edit.data.id) {
			const matchedItem = list.find(item => item.id === edit.data.id);
			if (matchedItem && matchedItem.agentName) {
				setAgentName(matchedItem.agentName);
			} else {
				setAgentName("");
			}
		}
	}, [activeTab, edit.data.id, list]);
	
	useEffect(() => {
		if(chatbotTrainingPhrases && chatbotTrainingPhrases.length > 0) {
			let list = [];
			chatbotTrainingPhrases.map((item) => {
				list.push(item.phrase);
			});
			setListOfChatBotTexts(list);
		} else {
			setListOfChatBotTexts([]);
		}
	}, [chatbotTrainingPhrases]);

	useEffect(() => {
		if(type == "KBT_QUESTION_ALL") {
			setListOfChatBotTexts([]);
			if(Object.keys(edit.data).length > 0) {
				let currAnswer = edit.data.answer;
				let text = edit.data.answer;
				if (typeof text != "undefined") {
					let sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
					let eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
					// assuming only one template data
					if (sidx != -1 && eidx != -1 && eidx > sidx) {
						currAnswer = text.split(FOLLOWUP_TEMPLATE_START)[0];
						// Get template
						text = stripHTML(edit.data.answer);
						sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
						eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
						if (sidx != -1 && eidx != -1 && eidx > sidx) {
							let dataTemplate = text.substring(sidx + FOLLOWUP_TEMPLATE_START.length, eidx).replace(/\s+/g, " ");
							if (dataTemplate != "") {
								setGenTemplate(true);
								const templateDataTemp = JSON.parse(dataTemplate);
								//fixme @sue, should have change with + button
								if(editorMode) {
									templateDataTemp.buttons.push(emptyTemplateButton);
								}
								setTemplateData(templateDataTemp);
							}
						}
					} else {
						setGenTemplate(false);
						setTemplateData(emptyFollowupTemplate);
					}
				}
				setReadableAnswer(currAnswer);

				let qParams = {};
				qParams.id = qid;
				qParams.subject = subject;
				qParams.question = question;
				qParams.answer = currAnswer;
				qParams.attachments = attachments;
				qParams.userComment = userComment;
				qParams.external = external;
				qParams.forBot = forBot;
				qParams.showInTopList = showInTopList;
				qParams.timeControlled = timeControlled;
				qParams.startDate = startDate;
				qParams.endDate = endDate;
				qParams.keyboardShortcut = keyboardShortcut;
				qParams.keyboardShortcutUseAlt = keyboardShortcutUseAlt;
				qParams.keyboardShortcutUseCtrl = keyboardShortcutUseCtrl;
				qParams.keyboardShortcutUseMeta = keyboardShortcutUseMeta;
				qParams.keyboardShortcutUseShift = keyboardShortcutUseShift;
				setQuestionParams(qParams);
			}
		}
		setVotedOnce(false);
		setVotedUp(false);
		setVotedDown(false);
		setCurrentVote (userVote.vote);
	}, [edit.data, chatbotList]);

	useEffect(() => {
		if(questionParams.answer) {
			let currAnswer = questionParams.answer;
			let text = questionParams.answer;
			if (typeof text != "undefined") {
				let sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
				let eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
				// assuming only one template data
				if (sidx != -1 && eidx != -1 && eidx > sidx) {
					currAnswer = text.split(FOLLOWUP_TEMPLATE_START)[0];
					// Get template
					text = stripHTML(edit.data.answer);
					sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
					eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
					if (sidx != -1 && eidx != -1 && eidx > sidx) {
						let dataTemplate = text.substring(sidx + FOLLOWUP_TEMPLATE_START.length, eidx).replace(/\s+/g, " ");
						if (dataTemplate != "") {
							setGenTemplate(true);
							const templateDataTemp = JSON.parse(dataTemplate);
							//fixme @sue, should have change with + button
							if(editorMode) {
								templateDataTemp.buttons.push(emptyTemplateButton);
							}
							setTemplateData(templateDataTemp);
						}
					}
				} else {
					setGenTemplate(false);
					setTemplateData(emptyFollowupTemplate);
				}
			}
			setReadableAnswer(currAnswer);
		}
	}, [questionParams.answer]);

	const prevId = useRef(edit.data.id);
	useEffect(() => {
	  if (edit.data.id !== prevId.current) {
		if(edit.data.id) {
			if(onCheckingChatbotIntentsForQuestion && edit.data.id > 0) {
				onCheckingChatbotIntentsForQuestion(edit.data.id, allChatbotIntents, chatbotList);
			}
		}
		prevId.current = edit.data.id;
	  }
	}, [edit.data.id]);
	useEffect(() => {
		if(showQuestionEditor) {
			if(edit.data.id > 0) {
				setCreateNew(false);
				setEditorMode(false);
			} else {
				setCreateNew(true);
				setEditorMode(true);
				resetParams();
			}
		}
	}, [edit.data.id, showQuestionEditor]);

	const questionTabs= [], questionNumTabs = 2;
	for (let i = 0; i < questionNumTabs; i++) {
		let qTabClass = '';
		let tabContentStyle = { display: 'none' };
		if (i == activeQuestionTab) {
			qTabClass = 'active';
			tabContentStyle = {};
		}
		questionTabs.push({ tabClass: qTabClass, contentStyle: tabContentStyle });
	};


	const handleEditQA = (editId, type) => {
		setEditorMode(true);
		onQuestionEdit(true);
	}
	const addAnswerBase = () => {
		if (chat) {
			const editor = CKEDITOR.instances["chatEditor"];
			if (editor) {
				const currentAns = editor.getData();
				const appended = currentAns + ui.shownAnswer;
				editor.setData(appended);
			}
		}
		let answer = ui.shownAnswer;
		// for non chat, strip off the template data from the message (if any)
		if (!chat) {
			let sidx = answer.indexOf(START_TEMPLATE_DATA)
			let eidx = answer.indexOf(END_TEMPLATE_DATA)
			if (sidx != -1 && eidx != -1 && eidx > sidx) {
				answer = answer.substring(0, sidx) + answer.substring(eidx+END_TEMPLATE_DATA.length, answer.length);
			}
		}
		handleAppendKnowledgeBase(
			answer,
			"",
			ui.shownAttachments,
			currentReply,
			chat,
			ui.shownId,
			popupMode
		);
	}
	const handleInsertAnswer = () => {
		new Promise(resolve => {
			const { questionNode } = ui;
			const { chatbots, forBot } = questionNode;
			const { shownId } = ui;
			const hasBot = noForBotQuestion ? chatbots && chatbots.length : forBot;
			if (!chat || !hasBot || !shownId) {
				resolve();
				return;
			}
			onRequestTrainChatbots(shownId, chatbots).catch(() => {
				if (process.env.NODE_ENV !== 'production') {
					console.log('dbg: no for training chatbot')
				}
			}).then(() => resolve());
		}).then(() => {
			addAnswerBase();
		});
	}
	const topRightIconStyle = {
		display: 'flex',
		cursor: 'pointer',
		float: 'right',
		gap: '8px'
	}
	const handleCloseQuestionInfo = () => {
		if(expandQuestionInfo) {
			onSetExpandQuestionInfo(false);
			onExpandQuestionInfo(false);
		}
		setEditorMode(false);
		onQuestionEdit(false);
		onShowAIPanel(false);
		if(!edit.data.id) {
			setCreateNew(false);
			if(onCloseQuestionInfo) {
				onCloseQuestionInfo();
			}
		} else{
			if(onCloseQuestionInfo && !editorMode) {
				onCloseQuestionInfo();
			}
		}
	}
	const handleExpandQuestionInfo = () => {
		onSetExpandQuestionInfo(!expandQuestionInfo);
		onExpandQuestionInfo(!expandQuestionInfo);
	}

	const rightPanelMode = forErrand && !popupMode;

	const ratingStyle = {
		display: !rightPanelMode ? 'flex' : 'inline-flex',
		gap: '8px',
		padding: '8px 0px',
		fontSize: '12px',
		fontWeight: '400',
		color: '#6d6d6d',
	}

	const handleInputChange = (name, value) => {
		let params = questionParams;
		if(name == "name") {
			params.subject = value;
		}
		params[name] = value;
		setQuestionParams(params);
	}
	const onHandleTextInputBlur = (event) => {
	}

	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [deleteWarning, setDeleteWarning] = useState("");
	const [deleteMsg, setDeleteMsg] = useState("");
	const [rejectMode, setRejectMode] = useState(false);
	const [confirmDeleteTxt, setConfirmDeleteTxt] = useState(I('Delete'));

	const handleDeleteQuestion = () => {
		const warningTitle = I('Are you sure you want to delete this question?');
		setConfirmDeleteTxt(I('Delete'));
		setShowDeleteAlert(true);
		setDeleteWarning(warningTitle);
	}
	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		if(rejectMode) {
			if (onReject != undefined) {
				onReject(edit.data.id);
			}
			setRejectMode(false);
		} else{
			if(onHandleRemove) {
				onHandleRemove(KBT_QUESTION, qid);
				onQuestionEdit(false);
				if(onCloseQuestionInfo) {
					onCloseQuestionInfo();
				}
			}
		}
	}
	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
		setRejectMode(false);
	}

	const handleSaveQuestion = () => {
		if(genTemplate) {
			const newAnswer = questionParams.answer + FOLLOWUP_TEMPLATE_START + JSON.stringify(templateData) + FOLLOWUP_TEMPLATE_END;
			questionParams.answer = newAnswer;
		}
		onHandleSave(KBT_QUESTION, questionParams);
		if(!isObjEmpty(trainingPhrasesParam)) {
			//just "add" api, no need to call entire intent update
			onAddTrainingPhrases(trainingPhrasesParam);
		}
		if (process.env.NODE_ENV !== 'production') {
			console.log("Dbg: trainingPhrasesToBeRemoved -> ", trainingPhrasesToBeRemoved);
		}
		if(trainingPhrasesToBeRemoved && trainingPhrasesToBeRemoved.length > 0) {
			//need to call intent api
			let allTrainingPhrases = [];
			if(chatbotsIntents && chatbotsIntents.training_phrases && chatbotsIntents.training_phrases.length > 0) {
				chatbotsIntents.training_phrases.map((item) => {
					allTrainingPhrases.push({name: item.name, phrase: item.phrase});
				});
				let newTrainingPhrases = [];
				allTrainingPhrases.map((item, idx) => {
					if(trainingPhrasesToBeRemoved.indexOf(idx) < 0) {
						newTrainingPhrases.push(item);
					}
				});
				if(onRemoveTrainingPhrases) {
					const chatbotId = edit.chatbotIds[0].toString();
					const intentId = edit.intentId;
					const p = {
						data: {
							"training_phrases": newTrainingPhrases
						},
						masks: [
							"training_phrases"
						]
					}
					const newCopyIntent = chatbotsIntents;
					newCopyIntent.training_phrases = newTrainingPhrases;
					onRemoveTrainingPhrases(chatbotId, intentId, p, newCopyIntent);
				}
			}
		}
		setEditorMode(false);
	}
	const handleReject = () => {
		setRejectMode(true);
		setConfirmDeleteTxt(I('Reject'));
		const warningTitle = I('Are you sure you want to reject this suggestion?');
		setShowDeleteAlert(true);
		setDeleteWarning(warningTitle);
		setDeleteMsg(I("This process cannot be undone"));
	}

	const handleQuestionTabClick = (tab) => {
		setActiveQuestionTab(tab);
	};

	const onHandleUpdateFollowUpData = (followUpData) => {
		if(followUpData) {
			setTemplateData(followUpData);
			setGenTemplate(true);
		}
	}

	const handleAddTrainingPhrases = (param) => {
		setTrainingPhrasesParam(param);
	}

	const handleRewrite = () => {
		const ansEditor = CKEDITOR.instances["Library_answer"];
		const ansTxt = ansEditor.document.getBody().getText();
		onShowAIPanel(true, ansTxt);
	}

	const handleRemoveTrainingPhrase = (param, list) => {
		const { index, chatbots } = param;
		if(index >= 0) {
			let newList = [];
			list.map((item, idx) => {
				if(idx != index) {
					newList.push(item);
				}
			});
			setListOfChatBotTexts(newList);
			setTrainingPhrasesToBeRemoved([...trainingPhrasesToBeRemoved, index]);
		}
	}

	useEffect(() => {
		if(userVote.vote > 0 || userVote.vote < 0) {
			setCurrentVote(userVote.vote);
			setShowCommentInput(true);
			setDisableRating(true);
		} else if(userVote.vote == null) {
			setCurrentVote(0);
			setShowCommentInput(false);
			setDisableRating(false);
		}
		if(userVote.comment) {
			setCurrentCommentsCount(1);
			setComment(userVote.comment);
			setShowCommentInput(true);
		} else {
			setCurrentCommentsCount(0);
			setComment("");
		}
	}, [userVote]);

	const handleVoteUp = () => {
		if(forErrand) {
			setVotedUp(true);
			setVotedDown(false);
			setShowCommentInput(true);
			if (userVote.vote === USER_VOTE_UP) {
				setCurrentVote(0);
			} else {
				setCurrentVote(USER_VOTE_UP);
			}
			setCommentReadOnly(false);
		}
	}
	const handleVoteDown = () => {
		if(forErrand) {
			setVotedDown(true);
			setVotedUp(false);
			setShowCommentInput(true);
			if (userVote.vote === USER_VOTE_DOWN) {
				setCurrentVote(0);
			} else {
				setCurrentVote(USER_VOTE_DOWN);
			}
			setCommentReadOnly(false);
		}
	}

	const handleSaveVote = () => {
		if(comment) {
			setCurrentCommentsCount(1);
			setComment(comment);
		}
		const params = {
			comment
			, id: edit.data.id
			, vote: currentVote
		}
		onUpdateUserVote(params);
		setCommentReadOnly(true);
		setVotedOnce(true);
	}

	const handleEditVote = () => {
		setCommentReadOnly(false);
		setDisableRating(false);
	}

	const handleRemoveVote = () => {
		const params = {
			comment: null
			, id: ui.shownId
			, vote: 0
		}
		onUpdateUserVote(params);
		setCommentReadOnly(true);
		setShowCommentInput(false);
		setDisableRating(false);
	}

	const headerBaseStyle = {padding: '8px 0px', display: 'flex'};
	const headerQuestionStyle = {fontWeight: '700', fontSize: '14px', lineHeight: '20px', color: '#6D6D6D', padding: '8px 0px', display: 'flex'};
	let editTxt = I('Edit'), editBtnStyle = {}, questionClass = 'kb-question-title';
	if(activeTab == 1) {
		editTxt = I('Accept');
		editBtnStyle = {width: '100%', textAlign: 'right'};
		questionClass += ' kb-suggestion-title';
	}
	const kbFooterStyle = {
		display: !rightPanelMode ? 'flex' : 'block',
		justifyContent: !rightPanelMode ? 'space-between' : '',
		padding: '8px',
		borderTop: '1px solid #f0f0f0',
		margin: '10px',
		flexDirection: 'column'
	}
	let overallUpVoteCount = "", overallDownVoteCount = "";
	if(!forErrand) {
		overallUpVoteCount = externalVotes + voteUp;
		overallDownVoteCount = voteDown;
	}

	return(
		<ExpandableDiv expand={expandQuestionInfo}>
					{
						!forErrand ?
						<div>
							<div style={topRightIconStyle}>
								<i className={expandQuestionInfo ? "icon-collapse" : "icon-expand"} onClick={() => handleExpandQuestionInfo()}></i>
								<i className="icon-times" onClick={() => handleCloseQuestionInfo()}></i>
							</div>
						</div> : null
					}
					<div style={{padding: '8px'}}>
						{
							editorMode &&
								<Fragment>
									<div key={"question-tab"+activeQuestionTab}>
										<ul className="nav nav-tabs">
											<li role="presentation" id={"libraryQuestionTab_0"} className="nav-item" data-qa-id="QA_tab_questions_edit">
												<a className={`nav-link ${questionTabs[0].tabClass}`} href="#"
													onClick={() => handleQuestionTabClick(0)} style={{marginRight : '20px'}}>{I('Edit question')}
												</a>
											</li>
											<li role="presentation" id={"libraryQuestionTab_1"} className="nav-item" data-qa-id="QA_tab_settings">
												<a className={`nav-link ${questionTabs[1].tabClass}`} href="#"
													onClick={() => handleQuestionTabClick(1)}>{I('Settings')}
												</a>
											</li>
										</ul>
										<div className="kb-table-container" style={questionTabs[0].contentStyle}>
											{
												activeQuestionTab == 0 ?
													<KBQuestionEditor
														data={questionParams}
														chatbots={edit.chatbotIds}
														listOfChatBotTexts={listOfChatBotTexts}
														editPageData={edit.data.editPage}
														insertionText={insertionText}
														templates={templates}
														fileArchiveImages={fileArchiveImages}
														onAppendTemplate={onAppendTemplate}
														onDragnDropFile={onDragnDropFile}
														onHandleInputChange={handleInputChange}
														onHandleTextInputBlur={onHandleTextInputBlur}
														onResetInsertionText={onResetInsertionText}
														onAddTrainingPhrases={handleAddTrainingPhrases}
														onRemoveTrainingPhrases={handleRemoveTrainingPhrase}
														onToggleRewritePanelAnswer={onToggleRewritePanelAnswer}
														onShowAIPanel={onShowAIPanel}
													/>
												: null
												}
										</div>
										<div className="kb-table-container" style={questionTabs[1].contentStyle}>
											{
												activeQuestionTab == 1 ?
													<KBQuestionSettings
														data={questionParams}
														showKeyboardShortcuts={showKeyboardShortcuts}
														generateTemplate={genTemplate}
														templateData={templateData}
														onHandleInputChange={handleInputChange}
														onHandleTextInputBlur={onHandleTextInputBlur}
														onUpdateFollowUpData={onHandleUpdateFollowUpData}
													/>
												: null
											}
										</div>
									</div>
								</Fragment>
						}
						</div>
						{
							!editorMode &&
							<Fragment>
								<div style={{ padding: '8px' }}>
									<HtmlRender
										html={questionParams.subject}
										customStyle={{ fontWeight: '700', fontSize: '16px', lineHeight: '20px', color: '#6D6D6D' }}
										icon={activeTab == 1 ? "icon-suggest-knowledgebase" : ""}
									/>
								</div>
								<div style={{ padding: '8px', display: 'flex', verticalAlign: 'top' }} className={questionClass}>
									<HtmlRender html={questionParams.question} customStyle={headerBaseStyle} list={true} disableLoader={true} />
								</div>
								<div style={{ padding: '0px 32px' }}>
									<HtmlRender html={readableAnswer} disableLoader={true}/>
								</div>
								{activeTab === 1 && agentName && (
    								<div style={{marginTop:'30px', fontSize:'10px', padding: '8px 32px', fontStyle: 'italic', color: '#6D6D6D' }}>
        								By {agentName}
   									</div>
								)}
								<div>
									{
										(listOfChatBotTexts && listOfChatBotTexts.length > 0) &&
										<div style={{ padding: '20px 0px', display: 'block', verticalAlign: 'top' }}>
											<div style={headerQuestionStyle}>{I("Chatbot training phrases")}</div>
											<div style={{ display: 'flex', gap: '4px' }}>
												{
													listOfChatBotTexts.map((item, index) => {
														return <TextWithBg key={"chatbot-training-phrase-" + index} >{item}</TextWithBg>
													})
												}
											</div>
										</div>
									}
								</div>
							</Fragment>
						}
					<div className='kb-question-footer' style={kbFooterStyle}>
						{
							editorMode ?
							<Fragment>
								<EditorFooter
									left={
										<div className="admin-footer-btn" hidden={false}>
											<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={handleDeleteQuestion} text={I("Delete question")} />
										</div>
									}
								>
									<button onClick={handleCloseQuestionInfo} className="btn-text grey" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={false} title={I("Cancel")}>
										{I('Cancel')}
									</button>
									{
										(features['openai-rewrite-answer'] && activeQuestionTab == 0 && activeTab !== 1) ?
											<button onClick={handleRewrite} className="btn-ai-pink-border" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px', height: '27px', width: '128px' }} disabled={false} title={I("Agent assist")}>
												<i className="icon-chatbot" style={{ marginRight: "5px" }}></i>
												{I('Agent assist')}
											</button> : ""
									}
									<SaveButton className={"btn-blue with-border-radius"} onClick={handleSaveQuestion} text={I("Save")} />
								</EditorFooter>
							</Fragment>
							:
							<Fragment>
								<div className='kb-attachment-footer kb-file-btns'>
									<div className='kb-files-wrapper'>
										<LibraryQuestionAttachmentList attachments={attachments} readOnly={true} />
									</div>
								</div>
								<div style={ratingStyle} hidden={activeTab == 1 ? true : false}>
									<IconWithCount icon={"icon-thumbs-up"} active={forErrand ? (votedUp || userVote.vote === USER_VOTE_UP) && !votedDown : false} count={overallUpVoteCount} forErrand={forErrand} disabled={disableRating} onClick={handleVoteUp}  />
									<IconWithCount icon={"icon-thumbs-down"} active={forErrand ? (votedDown || userVote.vote === USER_VOTE_DOWN) && !votedUp : false} count={overallDownVoteCount} forErrand={forErrand} disabled={disableRating} onClick={handleVoteDown} />
									<IconWithCount icon={"icon-comment"} count={forErrand ? currentCommentsCount : 0} title={forErrand ? comment : ""} notes={!forErrand ? comment : ""} active={false} forErrand={forErrand} />
								</div>
								<div className="kb-comment-save-wrapper" hidden={!forErrand}>
									{
										showCommentInput ?
										<TextInputRow
											id="QA_knowledgebase_comment_input"
											name="kb-comment-vote-input"
											className={classNames("form-control input-sm",
												{"inline": !popupMode}
												, {"readonly": commentReadOnly}
											)}
											value={comment}
											onChange={(event) => setComment(event.target.value)}
											readOnly={commentReadOnly}
										/> : null
									}
									{
										forErrand ?
										<div style={{display: 'inline-flex'}}>
											<IconWithCount className='kb-vote-save' icon={"far fa-save"} forErrand={forErrand} active={false} onClick={handleSaveVote} />
											{
												( votedOnce || (userVote && userVote.vote) ) ?
												<Fragment>
													<IconWithCount className='kb-vote-save' icon={"icon-edit"} forErrand={forErrand} active={false} onClick={handleEditVote} />
													<IconWithCount className='kb-vote-save' icon={"icon-trash"} forErrand={forErrand} active={false} onClick={handleRemoveVote} />
												</Fragment> : null
											}
										</div> : null
									}
								</div>
								<div style={editBtnStyle}>
									{
										activeTab == 1 &&
										<CustomButton className={"btn-blue-border"} onClick={handleReject} text={I("Reject")} />
									}
									{
										forErrand ? <CustomButton className={"btn-blue with-border-radius"} onClick={() => handleInsertAnswer(qid)} text={I("Use")} />
										: <CustomButton className={"btn-blue with-border-radius"} onClick={() => handleEditQA(qid, KBT_QUESTION)} text={editTxt} />
									}
								</div>
							</Fragment>
						}
					</div>
					<DeletePopup
						title={deleteWarning}
						msg={deleteMsg}
						icon={'icon-caution'}
						deleteTxt={confirmDeleteTxt}
						show={showDeleteAlert}
						onDelete={handleConfirmDelete}
						onClose={handleCancelDelete}
					/>
				</ExpandableDiv>
	)
});

KBExpandableDiv.displayName = 'KBExpandableDiv';


const AISidePanelsBase = ({
	className,
	showAIAnswerPanel,
	onMaximizePanel,
	onCloseAIAnswerPanel
}) => (
	<Fragment>
		<AIAnswerRightSidepanel
			className={className}
			show={showAIAnswerPanel}
			onMaximizePanel={onMaximizePanel}
			onClosePanel={onCloseAIAnswerPanel}
			>
			{
				<RewriteAnswerCntr src="KNOWLEDGE_BASE"/>
			}
		</AIAnswerRightSidepanel>
	</Fragment>
)

const AISidePanels = withUnmountWhenHidden(AISidePanelsBase)

const KBQuestionEditor = React.memo(({
	data,
	editPageData,
	chatbots,
	listOfChatBotTexts,
	templates,
	fileArchiveImages,
	onAppendTemplate,
	onDragnDropFile,
	onHandleInputChange,
	onHandleTextInputBlur,
	insertionText,
	onResetInsertionText,
	onAddTrainingPhrases,
	onRemoveTrainingPhrases,
	onToggleRewritePanelAnswer,
	onShowAIPanel
}) => {
	const [name, setName] = useState(data.subject);
	const [question, setQuestion] = useState(data.question);
	const [attachments, setAttachments] = useState(data.attachments ? data.attachments : []);
	const [answer, setAnswer] = useState(data.answer);
	const [hideQToolbar, setHideQToolbar] = useState(true);
	const [hideAToolbar, setHideAToolbar] = useState(true);

	useEffect(() => {
		preProcessAnswer(data.answer);
	}, []);

	useEffect(() => {
		if(data.subject == "") {
			setName("");
		} else {
			setName(data.subject);
		}
	}, [data.subject]);

	useEffect(() => {
		if(data.answer == "") {
			setAnswer(" ");
		} else {
			preProcessAnswer(data.answer);
		}
	}, [data.answer]);

	useEffect(() => {
		if(data.question == "") {
			setQuestion("");
		} else {
			setQuestion(data.question);
		}
	}, [data.question]);

	const preProcessAnswer = (ans) => {
		let currAnswer = ans;
		let text = ans;
		if (typeof text != "undefined") {
			let sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
			let eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
			if (sidx != -1 && eidx != -1 && eidx > sidx) {
				currAnswer = text.split(FOLLOWUP_TEMPLATE_START)[0];
			}
		}
		setAnswer(currAnswer);
	}

	const handleHideQToolbar = () => {
		setHideQToolbar(!hideQToolbar);
	}

	const handleHideAToolbar = () => {
		setHideAToolbar(!hideAToolbar);
	}

	const handleInputChange = (event) => {
		setName(event.target.value);
		onHandleInputChange(event.target.name, event.target.value);
	}

	const handleCkeditorChange = (event) => {
		const ckeditorName = event.editor.name;
		if(ckeditorName) {
			const editorName = ckeditorName.split('_')[1];
			if(editorName == "question") {
				setQuestion(event.editor.getData());
				onHandleInputChange("question", event.editor.getData());
			} else if(editorName == "answer") {
				setAnswer(event.editor.getData());
				onHandleInputChange("answer", event.editor.getData());
			}
		}
	}

	const handleKeyDown = (event) => {
	}

	const handleDragnDropFile = (fileData, fileInfo) => {
		if (onDragnDropFile) {
			return onDragnDropFile(fileData, fileInfo)
		}
	}

	const handleFileDrop = (file) => {
		const fileToBeUploaded = {
			id: file.id,
			name: file.value,
			size: file.estSize,
			url: file.download,
			add: true
		}
		let newAttachments = attachments;
		newAttachments.push(fileToBeUploaded);
		setAttachments(newAttachments);
		setAttachmentsParams(newAttachments);
	}
	const handleAppendTemplate = (id, ob) => {
		let tmplName = '[' + ob.Name + ']'
		onAppendTemplate(tmplName);
	}

	let fontFamily = "", fontSize = "", images = [], spellCheckLanguages = [];
	useEffect(() => {
		if (editPageData) {
			fontFamily = editPageData.fontFamily;
			fontSize = editPageData.fontSize;
			spellCheckLanguages = editPageData.languages;
			images = editPageData.fileArchiveImages;
		}
	}, [editPageData]);

	const handleAttachmentListChange = (list) => {
		setAttachments(list);
		setAttachmentsParams(list);
	}

	const setAttachmentsParams = (list) => {
		let addAttachments = [];
		let removeAttachments = [];
		if (list) {
			for (let i = 0; i < list.length; i++) {
				let attachment = list[i];
				if (attachment.remove) {
					removeAttachments.push(attachment.id)
				} else if (attachment.add) {
					addAttachments.push(attachment.id);
				}
			}
		}
		const toAddAttachments = addAttachments.join(',');
		const toRemoveAttachments = removeAttachments.join(',');
		onHandleInputChange("addAttachments", toAddAttachments);
		onHandleInputChange("removeAttachments", toRemoveAttachments);
	}

	const [showArchiveDD, setShowArchiveDD] = useState(false);

	const handleActionToggling = () => {
		setShowArchiveDD(!showArchiveDD);
	}
	const onSelectArchives = (selectedId) => {
		let list = [];
		let ifexist = false;
		let ifadded = false;
		const currAttachments = attachments;
		if (fileArchiveImages.length > 0) {
			for (var i = 0; i < fileArchiveImages.length; i++) {
				let fileArchiveImg = fileArchiveImages[i];
				if (selectedId == fileArchiveImg.id) {
					if (currAttachments.length > 0) {
						for (var j = 0; j < currAttachments.length; j++) {
							if (selectedId == currAttachments[j].id) {
								ifadded = currAttachments[j].add;
								ifexist = true;
							}
						}
						if (!ifexist || !ifadded) {
							list = currAttachments;
							const temp = { id: selectedId, name: fileArchiveImg.name, url: fileArchiveImg.src, size: fileArchiveImg.size, add: true, remove: false };
							list.push(temp);
						} else {
							list = currAttachments;
							alert("This image has already been selected");
						}
					} else {
						list = currAttachments;
						const temp = { id: selectedId, name: fileArchiveImg.name, url: fileArchiveImg.src, size: fileArchiveImg.size, add: true, remove: false };
						list.push(temp);
					}
				}
			}
		}
		setAttachments(list);
		setAttachmentsParams(list);
	}

	const handleAddTrainingPhrase = (id, text) => {
		const libraryQuestionId = data.id;
		const p = {
			libraryQuestionId,
			chatbots,
			text
		}
		onAddTrainingPhrases(p);
	}

	const handleRemoveTrainingPhrase = (id, index) => {
		const p = {
			chatbots,
			index
		}
		onRemoveTrainingPhrases(p, listOfChatBotTexts);
	}

	const [ agentAssistBtnTop, setAgentAssistBtnTop ] = useState(0);
	const [ agentAssistBtnLeft, setAgentAssistBtnLeft ] = useState(0);
	const [ showAgentAssistButton, setShowAgentAssistButton ] = useState(false);
	const [ selectedText , setSelectedText ] = useState("");

	const handleSelectText = (text, position, invalid) => {
		const cleanedSelection = text.replace(/[\n\r]/g, '');
		if(cleanedSelection.length > 0){
			setShowAgentAssistButton(true);
			setSelectedText(text);
			setAgentAssistBtnTop(position.top);
			setAgentAssistBtnLeft(position.left);
		} else {
			setShowAgentAssistButton(false);
			setSelectedText("");
		}
		if(invalid) {
			setShowAgentAssistButton(false);
		}
	}

	const handleEditorFocus = () => {
		setShowAgentAssistButton(false);
		setSelectedText("");
	}

	const handleOpenAgentAssist = () => {
		onShowAIPanel(true);
		if(onToggleRewritePanelAnswer) {
			const defaultAction = "proof-read";
			onToggleRewritePanelAnswer(true, selectedText,
				false, '', '',
				data.id, defaultAction);
		}
	}

	const agentAssistShortCutBtnStyle = {
		top: agentAssistBtnTop + 100,
		left: agentAssistBtnLeft,
	}

	return (
		<form className="admin-one-form edit-admin-form skills-admin-form">
			<div className='edit-admin-form'>
				<FormInputWrapper>
					<FormInputWithLabelRow
						label={I('Subject')}
						mandatory={false}
						inlineLabel={false}
					>
						<TextInputRow
							name="subject"
							className="admin-text-input with-helper"
							value={name}
							onChange={handleInputChange}
							onBlur={onHandleTextInputBlur}
							warning={""}
							inlineLabel={false}
							mandatory={true}
						/>
					</FormInputWithLabelRow>
				</FormInputWrapper>
				<FormInputWrapper>
					<div className={classNames("kb-question-editor", { "toolbarHidden": hideQToolbar })}>
						<ToolbarButtonIcon
							active={false}
							hidden={false}
							onClick={handleHideQToolbar}
						/>
						<FormInputWithLabelRow label={I("Question")} mandatory={false}>
							<Ckeditor
								id={'Library_question'}
								name={'Library_question'}
								data-qa-id='QA_KBQuestionEditor'
								toolbarID={'OneRow'}
								defaultFontFamily={fontFamily}
								defaultFontSize={fontSize}
								spellLanguages={spellCheckLanguages}
								defaultContent={question}
								hideSpellchecker={false}
								showResize={true}
								resizeMinHeight={100}
								height={50}
								verticalView={true}
								fileArchiveImages={fileArchiveImages}
								onKeydown={handleKeyDown}
								onChange={handleCkeditorChange}
								onDragnDropFiles={handleDragnDropFile}
								onFileDrop={handleFileDrop}
								defShowYoutube={true}
								>
							</Ckeditor>
						</FormInputWithLabelRow>
					</div>
				</FormInputWrapper>
				<FormInputWrapper>
					<div className={classNames("kb-answer-editor", { "toolbarHidden": hideAToolbar })}>
						<ToolbarButtonIcon
							active={false}
							hidden={false}
							onClick={handleHideAToolbar}
						/>
						<FormInputWithLabelRow label={I("Answer")} mandatory={false}>
							<TemplateAssist
								id={"kb-template-assist"}
								data-qa-id='QA_KBTemplateAssist'
								data={templates}
								onAppendItem={handleAppendTemplate}
								direction="down"
							/>
							<Ckeditor
								id={'Library_answer'}
								name={'Library_answer'}
								data-qa-id='QA_KBAnswerEditor'
								control={true}
								toolbarID={'OneRow'}
								defaultFontFamily={fontFamily}
								defaultFontSize={fontSize}
								spellLanguages={spellCheckLanguages}
								defaultContent={answer}
								hideSpellchecker={false}
								showResize={true}
								resizeMinHeight={100}
								height={100}
								verticalView={true}
								fileArchiveImages={fileArchiveImages}
								insertionText={insertionText}
								onResetInsertionText={onResetInsertionText}
								onKeydown={handleKeyDown}
								onChange={handleCkeditorChange}
								onDragnDropFiles={handleDragnDropFile}
								onFileDrop={handleFileDrop}
								defShowYoutube={true}
								onSelectText={handleSelectText}
								onFocus={handleEditorFocus}
								>
								{features["openai-rewrite-answer"] && showAgentAssistButton && (
									<AlphabetButton
										className="c3-aiButton-Style"
										label={"P"}
										onClick={handleOpenAgentAssist}
										customStyle={agentAssistShortCutBtnStyle}
									></AlphabetButton>
								)}
							</Ckeditor>
						</FormInputWithLabelRow>
					</div>
				</FormInputWrapper>
				<FormInputWithLabelRow label={I("Attachments")} mandatory={false} mainClassName={"kb-file-btns"} className='kb-files-wrapper'>
					<div style={{flex: '3', padding: '10px'}} className='kb-file-btns'>
							<LibraryQuestionAttachmentList attachments={attachments} onChange={handleAttachmentListChange} />
					</div>
					<div style={{flex: '1', padding: '10px'}} className='kb-file-btns'>
						<div className="attachFileArchive">
							<ActionDropdown2 id="archiveFilesKnowledgeB"
								title={<span className="archivefile">
									<a>{I("Choose File")}
										<i className={getChevronIcon(!showArchiveDD)} />
									</a>
								</span>}
								show={showArchiveDD}
								isHeader={true}
								headerText={I("Image archive")}
								direction={"up"}
								data={fileArchiveImages}
								onToggle={handleActionToggling}
								onSelectItem={onSelectArchives} />
						</div>
					</div>
				</FormInputWithLabelRow>
				{
					listOfChatBotTexts && listOfChatBotTexts.length > 0 &&
					<FormInputWithLabelRow
						label={I("Chatbot training phrase")}
						inlineLabel={false}
						mandatory={false}>
						<AddTextItemInputContainer
							id={"kbTrainingPhrases"+data.id}
							name={"kbTrainingPhrases"+data.id}
							placeholder={I("Add training phrase. Press ENTER to add phrase")}
							onAdd={handleAddTrainingPhrase}
							onRemove={handleRemoveTrainingPhrase}
							list={listOfChatBotTexts}
							useEnterKeyOnly={true}
							customClass={"kb-training-phrases"}
						/>
					</FormInputWithLabelRow>
				}
			</div>
		</form>
	)
});
KBQuestionEditor.displayName = 'KBQuestionEditor';

const BTTN_CTRL = 1
, BTTN_SHIFT = 2
, BTTN_ALT = 3
, BTTN_META = 4;

const buttonListShortCut = [
	BTTN_CTRL
	, BTTN_SHIFT
	, BTTN_ALT
	, BTTN_META
];

const shortCutKeyMap = {
	[BTTN_CTRL]: ["CTRL", ""]
	, [BTTN_SHIFT]: ["SHIFT", ""]
	, [BTTN_ALT]: ["ALT", ""]
	, [BTTN_META]: ["⌘ Command"]
}

const KeyButtonsElem = ({ onClick, pressedKeyList}) => {
	const keyList = pressedKeyList.map(obj => obj.id);
	return (
		<ButtonsPad disabled={false}>
		{buttonListShortCut.map(identifier => {
			const [
				primary
				, secondary
				, isIcon
				, className
			] = shortCutKeyMap[identifier];
			return (
				<OneButtonPad
					key={identifier}
					className={className}
					id={identifier}
					isPressed={keyList.indexOf(identifier.toString()) !== -1 ? true : false}
					onClick={onClick}
					primary={primary}
					primaryIsIcon={isIcon}
					secondary={secondary}
				/>
			);

		})}
	</ButtonsPad>
	)
}

const KBQuestionSettings = React.memo(({data, templateData, showKeyboardShortcuts, onUpdateFollowUpData, onHandleInputChange, onHandleTextInputBlur }) => {
	const [external, setExternal] = useState(data.external);
	const [showInTopList, setShowInTopList] = useState(data.showInTopList);
	const [timeControlled, setTimeControlled] = useState(data.timeControlled);
	const [startDate, setStartDate] = useState(data.startDate);
	const [endDate, setEndDate] = useState(data.endDate);

	const [keyboardShortcut, setKeyboardShortcut] = useState(data.keyboardShortcut);
	const [keyboardShortcutUseAlt, setKeyboardShortcutUseAlt] = useState(data.keyboardShortcutUseAlt);
	const [keyboardShortcutUseCtrl, setKeyboardShortcutUseCtrl] = useState(data.keyboardShortcutUseCtrl);
	const [keyboardShortcutUseMeta, setKeyboardShortcutUseMeta] = useState(data.keyboardShortcutUseMeta);
	const [keyboardShortcutUseShift, setKeyboardShortcutUseShift] = useState(data.keyboardShortcutUseShift);

	const [haveFollowUp, setHaveFollowUp] = useState(false);
	const [followUpQuestion, setFollowUpQuestion] = useState("");
	const [keyPressed, setKeyPressed] = useState([]);

	useEffect(() => {
		if(data) {
			let keyList = [];
			if(data.keyboardShortcutUseCtrl) {
				keyList.push({id: BTTN_CTRL.toString(), keyId: BTTN_CTRL, name: "CTRL", description: "", actiontype: ""});
			}
			if(data.keyboardShortcutUseAlt) {
				keyList.push({id: BTTN_ALT.toString(), keyId: BTTN_ALT, name: "ALT", description: "", actiontype: ""});
			}
			if(data.keyboardShortcutUseShift) {
				keyList.push({id: BTTN_SHIFT.toString(), keyId: BTTN_SHIFT, name: "SHIFT", description: "", actiontype: ""});
			}
			if(data.keyboardShortcutUseMeta) {
				keyList.push({id: BTTN_META.toString(), keyId: BTTN_META, name: "⌘ Command", description: "", actiontype: ""});
			}
			setKeyPressed(keyList);
		}
	}, [data]);

	useEffect(() => {
		if(templateData) {
			if(templateData.text != "") {
				setHaveFollowUp(true);
				setFollowUpQuestion(templateData.text);
			} else {
				setHaveFollowUp(false);
			}
		}
	}, [templateData]);

	const handleInputChange = (event) => {
		const inputName = event.target.name;
		if(inputName == "followUpQuestion") {
			setFollowUpQuestion(event.target.value);
			let tempData = templateData;
			tempData.text = event.target.value;
			onUpdateFollowUpData(tempData);
		} else if(inputName == "keyboardShortcut") {
			setKeyboardShortcut(event.target.value);
		} else {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: input not found -> ", inputName);
			}
		}
		onHandleInputChange(event.target.name, event.target.value);
	}

	const handleToggleSwitch = (value, field) => {
		if(field == "external") {
			setExternal(value);
		} else if(field == "showInTopList") {
			setShowInTopList(value);
		} else if(field == "timeControlled") {
			setTimeControlled(value);
		}
		if(field == "haveFollowUp") {
			setHaveFollowUp(value);
		} else {
			onHandleInputChange(field, value);
		}
	}
	const handleDateChange = (name, date) => {
		if(name == "startDate") {
			setStartDate(date);
		} else if(name == "endDate") {
			setEndDate(date);
		}
		onHandleInputChange(name, date);
	}
	const removeFollowUpData = (index) => {
		let tempData = templateData;
		tempData.buttons.splice(index, 1);
		onUpdateFollowUpData(tempData);
	}

	const handleFollowUpDataChange = (index, event) => {
		const inputName = event.target.name;
		const inputValue = event.target.value;
		let tempData = templateData;
		let field = "";
		if(inputName.indexOf("title") != -1) {
			field = "title";
		} else if(inputName.indexOf("data") != -1) {
			field = "data";
		}
		let newTempData = {
			buttons: {
				[index]: { [field]: { $set: inputValue } }
			}
		}
		tempData = update(tempData, newTempData);
		if (index == tempData.buttons.length - 1 &&
			tempData.buttons[index].title != '' &&
			tempData.buttons[index].data != '') {
			tempData = update(tempData, {
				buttons: { $push: [emptyTemplateButton] }
			});
		}
		onUpdateFollowUpData(tempData);
	}

	const addKeyClick = (val) => {
		if(val == BTTN_CTRL) {
			setKeyboardShortcutUseCtrl(!keyboardShortcutUseCtrl);
			onHandleInputChange("keyboardShortcutUseCtrl", !keyboardShortcutUseCtrl);
		} else if(val == BTTN_ALT) {
			setKeyboardShortcutUseAlt(!keyboardShortcutUseAlt);
			onHandleInputChange("keyboardShortcutUseAlt", !keyboardShortcutUseAlt);
		} else if(val == BTTN_SHIFT) {
			setKeyboardShortcutUseShift(!keyboardShortcutUseShift);
			onHandleInputChange("keyboardShortcutUseShift", !keyboardShortcutUseShift);
		} else if(val == BTTN_META) {
			setKeyboardShortcutUseMeta(!keyboardShortcutUseMeta);
			onHandleInputChange("keyboardShortcutUseMeta", !keyboardShortcutUseMeta);
		}

		let keySet = {};
		let keyList = keyPressed;
		let toBeRemoved = false;
		if(val == BTTN_CTRL && !keyboardShortcutUseCtrl) {
			keySet = {id: BTTN_CTRL.toString(), keyId: BTTN_CTRL, name: "CTRL", description: "", actiontype: ""};
		} else if(val == BTTN_CTRL && keyboardShortcutUseCtrl) {
			toBeRemoved = true;
		}
		if(val == BTTN_ALT && !keyboardShortcutUseAlt) {
			keySet = {id: BTTN_ALT.toString(), keyId: BTTN_ALT, name: "ALT", description: "", actiontype: ""};
		} else if(val == BTTN_ALT && keyboardShortcutUseAlt) {
			toBeRemoved = true;
		}
		if(val == BTTN_SHIFT && !keyboardShortcutUseShift) {
			keySet = {id: BTTN_SHIFT.toString(), keyId: BTTN_SHIFT, name: "SHIFT", description: "", actiontype: ""};
		} else if(val == BTTN_SHIFT && keyboardShortcutUseShift) {
			toBeRemoved = true;
		}
		if(val == BTTN_META && !keyboardShortcutUseMeta) {
			keySet = {id: BTTN_META.toString(), keyId: BTTN_META, name: "⌘ Command", description: "", actiontype: ""};
		} else if(val == BTTN_META && keyboardShortcutUseMeta) {
			toBeRemoved = true;
		}
		if(toBeRemoved) {
			keyList = keyList.filter((item) => {
				return item.id != val;
			});
			setKeyPressed(keyList);
		} else {
			setKeyPressed([
				...keyList,
				keySet
			]);
		}
	};

	return (
		<form className="admin-one-form edit-admin-form skills-admin-form" >
			<div className='edit-admin-form'>
				<FormHeader text={I("General")} />
				<FormInputWrapper>
					<div style={{ width: '50%' }}>
						<FormInputWithLabelRow
							label={I("Show in external")}
							warning={""}
							className={"admin-select-popup-type"}
							inlineLabel={false}
						>
							<ToggleSwitch
								id={"external"}
								name={"external"}
								data-qa-id="kbQuestionExternal"
								label={""}
								checked={external}
								handleToggle={(toggle) => handleToggleSwitch(toggle, "external")}
								listWrap={false}
								className="admin-text-input chat-widget"
								inlineLabel={false}
							/>
							<Helper>{I("Check this box if the question will be available on a web site. This will allow you add things to either the question or the answer which is not seen when including the answer in an email such as embedded YouTube videos.")}</Helper>
						</FormInputWithLabelRow>
					</div>
					<div style={{ width: '50%' }}>
						<FormInputWithLabelRow
							label={I("Show in top list")}
							warning={""}
							className={"admin-select-popup-type"}
							inlineLabel={false}
						>
							<ToggleSwitch
								id={"showInTopList"}
								name={"showInTopList"}
								data-qa-id="kbQuestionShowInTopList"
								label={""}
								checked={showInTopList}
								handleToggle={(toggle) => handleToggleSwitch(toggle, "showInTopList")}
								listWrap={false}
								className="admin-text-input chat-widget"
								inlineLabel={false}
							/>
							<Helper>{I("Check this box if you want this question to be shown in the top list.")}</Helper>
						</FormInputWithLabelRow>
					</div>
				</FormInputWrapper>
				<FormInputWrapper>
					<div style={{ width: '50%' }}>
						<FormInputWithLabelRow
							label={I("Time controlled")}
							warning={""}
							className={"admin-select-popup-type"}
							inlineLabel={false}
						>
							<ToggleSwitch
								id={"timeControlled"}
								name={"timeControlled"}
								data-qa-id="kbTimeControlled"
								label={""}
								checked={timeControlled}
								handleToggle={(toggle) => handleToggleSwitch(toggle, "timeControlled")}
								listWrap={false}
								className="admin-text-input chat-widget"
								inlineLabel={false}
							/>
							<Helper>{I("Check this box if you want this question to be time controlled. When a question is time controlled it is only visible between the start and the end date.")}</Helper>
						</FormInputWithLabelRow>
					</div>
					<div style={{ width: '50%' }}>
						<FormInputWithLabelRow
							label={I("Follow up")}
							warning={""}
							className={"admin-select-popup-type"}
							inlineLabel={false}
						>
							<ToggleSwitch
								id={"haveFollowUp"}
								name={"haveFollowUp"}
								data-qa-id="kbFollowUp"
								label={""}
								checked={haveFollowUp}
								handleToggle={(toggle) => handleToggleSwitch(toggle, "haveFollowUp")}
								listWrap={false}
								className="admin-text-input chat-widget"
								inlineLabel={false}
							/>
							<Helper>{I("Check this box if you want to add follow up question for this answer.")}</Helper>
						</FormInputWithLabelRow>
					</div>
				</FormInputWrapper>
				{
					showKeyboardShortcuts &&
					<Fragment>
						<FormHeader text={I("Keyboard shortcut")} />
						<FormInputWrapper>
							<FormInputWithLabelRow
								label={I("Chat shortcut")}
								warning={""}
								className={"kb-input-with-icon admin-select-popup-type"}
								inlineLabel={false}
								helperTxt={I("Here you can configure a keyboard shortcut which can be used to insert the answer while chatting. Check the modifiers you would like to use and then enter the key in the text box.")}
							>
								<TextInputRow
									name="keyboardShortcut"
									className="admin-text-input with-helper"
									value={keyboardShortcut}
									onChange={handleInputChange}
									onBlur={onHandleTextInputBlur}
									warning={""}
									inlineLabel={false}
									mandatory={true}
								/>
								<div className="input-post-icon"><i className='icon-keyboard' /></div>
							</FormInputWithLabelRow>
						</FormInputWrapper>
						<FormInputWrapper>
							<div className='c3-simple-dialpad dialpad compact ivr-admin kb-shortcuts'>
								<KeyButtonsElem onClick={addKeyClick} pressedKeyList={keyPressed} />
							</div>
						</FormInputWrapper>
					</Fragment>
				}
				{
					timeControlled &&
					<Fragment>
						<FormHeader text={I("Time controlled")} />
						<FormInputWrapper>
							<div style={{ width: '50%' }}>
								<FormInputWithLabelRow
									label={I("Start date")}
									warning={""}
									className={"kb-input-with-icon admin-select-popup-type"}
									inlineLabel={false}
								>
									<BasicDatePicker id={data.subject + '_startDate'} input={startDate} onChange={(date) => handleDateChange("startDate", date)} />
								</FormInputWithLabelRow>
							</div>
							<div style={{ width: '50%' }}>
								<FormInputWithLabelRow
									label={I("End date")}
									warning={""}
									className={"kb-input-with-icon admin-select-popup-type"}
									inlineLabel={false}
								>
									<BasicDatePicker id={data.subject + '_endDate'} input={endDate} onChange={(date) => handleDateChange("endDate", date)} />
								</FormInputWithLabelRow>
							</div>
						</FormInputWrapper>
					</Fragment>
				}
				{
					haveFollowUp &&
					<Fragment>
						<FormHeader text={I("Follow up question")} />
						<FormInputWrapper>
							<FormInputWithLabelRow
								label={I("Question")}
								warning={""}
								className={"kb-input-with-icon admin-select-popup-type"}
								inlineLabel={false}
							>
								<TextInputRow
									name="followUpQuestion"
									className="admin-text-input with-helper"
									value={followUpQuestion}
									onChange={handleInputChange}
									onBlur={onHandleTextInputBlur}
									warning={""}
									inlineLabel={false}
									mandatory={true}
								/>
							</FormInputWithLabelRow>
						</FormInputWrapper>
						{
							templateData.buttons.map((item, index) => {
								return <KBQuestionFollowUpData
									key={"kb-question-follow-up-data-" + index}
									id={index+1}
									title={item.title}
									data={item.data}
									onHandleInputChange={(e) => handleFollowUpDataChange(index, e)}
									onHandleTextInputBlur={onHandleTextInputBlur}
									onHandleRemoveFollowUpData={() => removeFollowUpData(index)}
								/>
							})
						}
					</Fragment>
				}
			</div>
		</form>
	)
});
KBQuestionSettings.displayName = 'KBQuestionSettings';

const KBQuestionFollowUpData = React.memo(({id, title, data, onHandleInputChange, onHandleTextInputBlur, onHandleRemoveFollowUpData}) => {
	return (
		<FormInputWrapper>
			<div style={{ width: '45%' }}>
				<FormInputWithLabelRow
					label={I("Button") + " " + id}
					warning={""}
					className={"kb-input-with-icon admin-select-popup-type"}
					inlineLabel={false}
				>
					<TextInputRow
						name={"title" + id}
						className="admin-text-input with-helper"
						value={title}
						onChange={onHandleInputChange}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
					/>
				</FormInputWithLabelRow>
			</div>
			<div style={{ width: '45%' }}>
				<FormInputWithLabelRow
					label={I("Data") + " " + id}
					warning={""}
					className={"kb-input-with-icon admin-select-popup-type"}
					inlineLabel={false}
				>
					<TextInputRow
						name={"data" + id}
						className="admin-text-input with-helper"
						value={data}
						onChange={onHandleInputChange}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
					/>
				</FormInputWithLabelRow>
			</div>
			<div style={{ border: '1px solid #EAEAEA', padding: '4px', borderRadius: '4px', width: '24px', height: '24px', margin: '20px 0px', color: '#969696' }}>
				<span style={{ cursor: 'pointer' }} onClick={onHandleRemoveFollowUpData}><i className='icon-trash'></i></span>
			</div>
		</FormInputWrapper>
	)
});

const IconWithCount = React.memo(({icon, count, active, className, title, notes, forErrand, disabled, onClick}) => {
	const baseStyle = {
		display: 'flex',
		padding: '8px',
		gap: '4px',
		cursor: forErrand ? (disabled ? 'not-allowed' : 'pointer') : 'pointer',
	}
	const countStyle = {
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '16px',
		color: '#6d6d6d'
	}
	if(active) {
		baseStyle.color = '#0C87F7';
		baseStyle.backgroundColor = '#E0F3FF';
		countStyle.color = '#0C87F7';
	}
	return (
		<div style={baseStyle} className={className} title={title} onClick={onClick} >
			<i className={icon} />
			{ (count && count != 0) ? <span style={countStyle}>{count}</span> : ""}
			{
				notes &&
				<span style={countStyle}>{notes}</span>
			}
		</div>
	)
});


const HtmlRender = memo(({ html, customStyle, list = false, disableLoader = false, icon }) => {
  const [loading, setLoading] = useState(false);
  const htmlRef = useRef(null);

  useEffect(() => {
    const updateHtmlContent = async () => {
      try {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        if (htmlRef.current) {
          htmlRef.current.innerHTML = '';

          const htmlContent = list ? `<span class='c3-renderer-spacer'></span>${html}` : html;
          htmlRef.current.innerHTML = htmlContent;
        }
      } catch (error) {
        console.error('Error updating HTML content:', error);
      } finally {
        setLoading(false);
      }
    };

    updateHtmlContent();
  }, [html, list]);


  const wrapperClass = list ? 'kb-title-header-list' : '';
  const combinedStyle = icon 
    ? { ...customStyle, display: 'inline-flex', gap: '10px' } 
    : customStyle;

  return (
    <div style={combinedStyle}>
      {!disableLoader && loading && <Spinner />}
      {!loading && icon && <i className={icon}></i>}
      <div ref={htmlRef} className={wrapperClass}></div>
    </div>
  );
});

export const KBUpdateModal = ({
	show,
	treeMode,
	type,
	dataSet = {},
	activeLibrary,
	activeCategory,
	libraryList,
	categoryList,
	treeList,
	onClose,
	onSave,
	onDelete
}) => {
	const [nameInput, setNameInput] = useState("");
	const [externalInput, setExternalInput] = useState(false);
	const [title, setTitle] = useState("");
	const [isNew, setIsNew] = useState(true);
	const [disableNameInput, setDisableNameInput] = useState(false);

	useEffect(() => {
		let headerTitle = isNew ? I("Create knowledge base") : I("Knowledge base settings");
		if(type == KBT_CATEGORY) {
			headerTitle = isNew ? I("Create category") : I("Category settings");
		} else if(type == KBT_QUESTION) {
			headerTitle = isNew ? I("Create question and answer") : I("Question and answer settings");
		}
		setTitle(headerTitle);
	}, [type, isNew]);

	useEffect(() => {
		if(dataSet && Object.keys(dataSet).length > 0) {
			if(dataSet.name) {
				setNameInput(dataSet.name);
			}
			if(typeof dataSet.external !== "undefined") {
				setExternalInput(dataSet.external);
			}
			setIsNew(false);
		} else {
			setNameInput("");
			setExternalInput(false);
			setIsNew(true);
		}
	}, [dataSet]);

	const [showCategory, setShowCategory] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [showParent, setShowParent] = useState(false);
	const [selectedParent, setSelectedParent] = useState("");
	const [parentType, setParentType] = useState("");
	const [parentList, setParentList] = useState([]);
	const [activeParentCategories, setActiveParentCategories] = useState([]);

	useEffect(() => {
		if(activeCategory > 0) {
			setSelectedParent(activeCategory);
		}
	}, [activeCategory]);

	useEffect(() => {
		if(treeMode) {
			let temp = [];
			if(categoryList && categoryList.length > 0) {
				categoryList.forEach((cat) => {
					let found = false;
					if(treeList && treeList.length > 0) {
						if(treeList[0].list && treeList[0].list.length > 0) {
							treeList[0].list.forEach((treeCat) => {
								if(cat.id == treeCat.id) {
									found = true;
								}
							});
						}
					}
					if(!found) {
						temp.push(cat);
					}
				});
			}
			setCategoryOptions(temp);
		}
	}, [treeList]);

	useEffect(() => {
		const parentListTemp = [];
		if(!treeMode) {
			//library as parent
			if(libraryList && libraryList.length > 0) {
				let temp = {
					id: 1,
					name: "Library",
					list: libraryList
				}
				parentListTemp.push(temp);
			}
			setParentList(parentListTemp);
			setParentType(KBT_LIBRARY);
		} else {
			if(activeCategory > 0) {
				//category as parent
				if(activeParentCategories && activeParentCategories.length > 0) {
					let temp = {
						id: 2,
						name: "Category",
						list: activeParentCategories
					}
					parentListTemp.push(temp);
					setParentList(parentListTemp);
					setParentType(KBT_CATEGORY);
				}
			}
		}
	}, [treeMode, activeParentCategories]);

	useEffect(() => {
		const catListTemp = [];
		const parentListTemp = [];
		if(treeMode) {
			if(activeCategory > 0) {
				if(categoryList && categoryList.length > 0) {
					categoryList.forEach((cat) => {
						if(cat.parentLibraries && cat.parentLibraries.length > 0) {
							cat.parentLibraries.forEach((lib) => {
								if(lib.id == activeLibrary) {
									catListTemp.push(cat);
								}
							});
						}
					});
					setActiveParentCategories(catListTemp);
				}
			} else {
				//no active category. just library as parent
				if(libraryList && libraryList.length > 0) {
					let temp = {
						id: 1,
						name: "Library",
						list: libraryList
					}
					parentListTemp.push(temp);
				}
				setParentList(parentListTemp);
				setParentType(KBT_LIBRARY);
				//set active library as default parent
				setSelectedParent(activeLibrary);
			}
		}
	}, [activeLibrary, activeCategory]);

	const handleToggleCategory = () => {
		setShowCategory(!showCategory);
	}

	const handleSelectCategory = (selected) => {
		setSelectedCategory(selected);
		setDisableNameInput(true);
	}

	const handleToggleParent = () => {
		setShowParent(!showParent);
	}
	const handleSelectParent = (selected) => {
		setSelectedParent(selected);
	}

	let titleIcon = isNew ? "icon-add" : "icon-edit";
	const handleChangeInput = (e) => {
		setNameInput(e.target.value);
	}
	const handleSave = () => {
		let data = dataSet;
		data.name = nameInput;
		data.external = externalInput;

		if(nameInput == "" && selectedCategory == "") {
			alert(INPUT_EMPTY_WARNING)
			return;
		}

		if(selectedParent) {
			if(parentType === KBT_LIBRARY) {
				data.parentLibrary = selectedParent;
			} else if(parentType === KBT_CATEGORY) {
				data.parentLibrary = activeLibrary;
				data.parentCategory = selectedParent;
			}
		} else {
			if(activeLibrary > 0 && activeCategory > 0) {
				data.parentCategory = activeCategory;
				data.parentLibrary = activeLibrary;
			} else {
				if(activeLibrary) {
					data.parentLibrary = activeLibrary;
				}
			}
		}
		if(selectedCategory) {
			data.currentCategory = selectedCategory;
		}
		onSave(type, data);
	}

	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [deleteWarning, setDeleteWarning] = useState("");

	const handleDelete = () => {
		let warningTitle = I('Are you sure you want to delete this library?');
		if(type === KBT_CATEGORY) {
			warningTitle = I('Are you sure you want to delete this category?');
		}
		setShowDeleteAlert(true);
		setDeleteWarning(warningTitle);
	}
	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		if(onDelete) {
			if(type === KBT_CATEGORY) {
				onDelete(KBT_CATEGORY, dataSet.id);
			} else if (type === KBT_LIBRARY) {
				onDelete(KBT_LIBRARY, dataSet.id);
			}
			onClose();
		}
	}
	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
	}

	const handleToggle = () => {
		setExternalInput(!externalInput);
	}
	let saveTxt = isNew ? I("Create") : I("Save");
	let deleteTxt = I("Delete knowledge base");
	if(type == KBT_CATEGORY) {
		deleteTxt = I("Delete category");
	}

	const orStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 'auto',
		padding: '10px 10px 0px 10px'
	}

	return <PopupPage
		data-qa-id="popup-form-backdrop-popup"
		extraClass="popup-form-backdrop general-popup-modal-backdrop"
		innerClass="popup-form general-popup-modal"
		show={show}
		onClose={onClose}
	>	<div id="" style={{width: '100%'}}>
			<div className="popup-title">
				<h2><i className={titleIcon}></i>{title}</h2>
			</div>
			<div className="popup-content">
				<form className="admin-one-form edit-admin-form skills-admin-form">
					<div className='edit-admin-form'>
						<Fragment>
							<div id="knowledgebase-modal-form" className="adminEdit">
								<div className="admin-inline admin-input-wrapper-four-one">
									<FormInputWithLabelRow
										label={I('Name')}
										mandatory={true}
										inlineLabel={false}
									>
										<TextInputRow
											name="libraryInputName"
											className="admin-text-input with-helper"
											value={nameInput}
											onChange={handleChangeInput}
											warning={""}
											inlineLabel={false}
											mandatory={true}
											helperTxt={I("The name of the knowledge base.")}
											readonly={disableNameInput}
										/>
									</FormInputWithLabelRow>
									{
										isNew && treeMode && type == KBT_CATEGORY &&
										<Fragment>
											<div style={orStyle}>{I("Or")}</div>
											<FormInputWithLabelRow
												label={I('Select current')}
												mandatory={true}
												inlineLabel={false}
											>
												<SingleSelectDD
													id={"kb-modal-select-parent"}
													textNoItemSelected={I("Select category")}
													className={"popup-multi-select"}
													data={categoryOptions}
													idFields={{id: "id", name: "name"}}
													selected={selectedCategory}
													onSelect={handleSelectCategory}
													onToggle={handleToggleCategory}
													show={showCategory}
												/>
											</FormInputWithLabelRow>
										</Fragment>
									}
									{
										type == KBT_LIBRARY &&
										<FormInputWithLabelRow
											label={I('External')}
											mandatory={false}
											inlineLabel={false}
										>
											<SwitchCheckbox
												active={externalInput}
												buttonClassName={""}
												id={"knowledgeBaseExternal"}
												name={"external"}
												type="chekbox"
												onClick={handleToggle}
												helperTxt={I("Check this box if the knowledge base will be available on a web site. External knowledge bases are not shown internally to agents.")}
												/>
										</FormInputWithLabelRow>
									}
								</div>
								<div className='admin-input-wrapper-half'>
								{
									isNew && type == KBT_CATEGORY && parentList.length > 0 &&
									<FormInputWithLabelRow
										label={I('Parent')}
										mandatory={false}
										inlineLabel={false}
									>
										<SingleSelectDD
											id={"kb-modal-select-parent"}
											textNoItemSelected={I("Select parent")}
											className={"popup-multi-select"}
											nested={{key: 'list'}}
											data={parentList}
											idFields={{id: "id", name: "name"}}
											selected={selectedParent}
											onSelect={handleSelectParent}
											onToggle={handleToggleParent}
											show={showParent}
										/>
									</FormInputWithLabelRow>
								}
								</div>
							</div>
						</Fragment>
					</div>
				</form>
			</div>
			<div className='popup-footer'>
				<EditorFooter
					left={
						<div className="admin-footer-btn" hidden={isNew}>
							<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={handleDelete} text={deleteTxt} />
						</div>
					}
				>
					<CancelButton onClick={onClose} text={I('Cancel')} />
					<SaveButton className={"btn-blue"} onClick={handleSave} text={saveTxt} />
				</EditorFooter>
			</div>
			<DeletePopup
				title={deleteWarning}
				msg={""}
				icon={'icon-caution'}
				show={showDeleteAlert}
				onDelete={handleConfirmDelete}
				onClose={handleCancelDelete}
			/>
		</div>
	</PopupPage>
}

export const KBErrandsModal = ({
	show,
	onMinimize,
	onClose,
	innerClass
}) => {
	return <PopupPage
		data-qa-id="popup-form-backdrop-popup"
		extraClass="popup-form-backdrop general-popup-modal-backdrop knowledge-base-backdrop"
		innerClass={"popup-form general-popup-modal library-modal " +innerClass}
		show={show}
		onMinimize={onMinimize}
		minimizeIcon={"icon-collapse"}
		onClose={onClose}
	>	<div id="" style={{width: '100%'}}>
			<div className="popup-title">
				<h2>{I("Knowledge bases")}</h2>
			</div>
			<div className="popup-content">
				<KnowledgeBaseCtnr forErrand={true} popupMode={true} />
			</div>
		</div>
	</PopupPage>
}


KnowledgeBase.displayName = 'KnowledgeBase';

export default KnowledgeBase;
